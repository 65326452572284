.root {
  display: flex;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.plus {
  margin-right: 5px;
  font-size: 20px;
  margin-top: -4px;
  color: white;
}

.plus.over {
  color: orange;
}
