.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 36px;
}

.icon img {
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.results {
  font-size: 14px;
}
