.root {
  padding: 20px;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.split {
  display: flex;
}

.left {
  width: 50%;
}

.divider {
  width: 1px;
  height: 250px;
  margin-top: 50px;
  background: #309acf;
}

.right {
  width: 45%;
  margin-left: 5%;
  position: relative;
}

.title {
  font-size: 18px;
  color: #5a5a5a;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.title .total {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
}

.left .row {
  display: flex;
  height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #309acf;
  width: 80%;
  margin-left: 10%;
  justify-content: space-between;
}

.right .chart {
  height: 150px;
}

.right .hours {
  position: absolute;
  top: 85px;
  left: 115px;
  display: flex;
}

.right .minutes {
  position: absolute;
  top: 105px;
  left: 115px;
  display: flex;
}

.right .value {
  font-size: 22px;
  color: #5a5a5a;
  width: 20px;
  text-align: right;
}

.right .title {
  font-size: 12px;
  color: #5a5a5a;
  margin-top: 8px;
  margin-left: 5px;
}

.right .legend {
}

.right .legend .row {
  display: flex;
}

.right .legend .row .icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.right .legend .row .text {
  width: 175px;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 10px;
}

.right .legend .row .line {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.right .legend .row .value {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
}

.meter {
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
  margin-left: 35px;
  margin-bottom: -60px;
  transform: scale(1.1);
}
