.tooltip {
  top: 5px;
  transform: translateX(-50%);
  padding: 15px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  color: #444547;
  box-sizing: border-box;
  min-width: 200px;
  font-family: ProximaNova;
  border-top: 4px solid #2191cf;
}

.title {
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
}

.arrow {
}
