.root {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.selected {
  background: black;
}

.root img {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 1px;
}

.root.selected img {
  margin-bottom: 2px;
}
