.root {
  width: 100%;
  min-height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.title {
  font-size: 22px;
  font-weight: bold;
  margin-left: 15px;
}
