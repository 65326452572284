.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.popup {
  position: absolute;
  width: 970px;
  height: Calc(100% - 250px);
  max-height: 571px;
  background: white;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  top: 200px;
  padding: 15px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  height: 16px;
  width: 12px;
  cursor: pointer;
}

.top {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.top .type {
  width: 165px;
  background-color: #e2e2e2;
  padding: 12px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(248, 157, 2);
  margin-right: 20px;
}

.top .students {
  text-align: center;
}

.top .students .assessment {
  margin-top: -35px;
  font-size: 11px;
  font-weight: bold;
  color: #777777;
}

.top .title {
  font-size: 20px;
  font-weight: bold;
  color: #282828;
  line-height: 60px;
  margin-left: 20px;
}

.rows {
  overflow-y: auto;
  max-height: Calc(100% - 150px);
}

.row {
  display: flex;
}

.row > div {
  border: 1px solid #e2e2e2;
  padding: 10px;
}

.row > div:nth-child(1) {
  width: 279px;
}

.row > div:nth-child(2) {
  width: 65px;
}
.row > div:nth-child(3) {
  width: 249px;
}
.row > div:nth-child(4) {
  width: 75px;
}

.header {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  text-align: center;
  background: #edf6f9;
}

.header:nth-child(1) {
  justify-content: flex-start;
}

.header:nth-child(3) {
  background-color: #b4dae5;
}

.row .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row input {
  margin-right: 10px;
}

.box {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #282828;
  text-align: center;
}

.box:nth-child(1) {
  justify-content: flex-start;
}

.button {
  width: 130px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

.footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
