.root {
  font-size: 14px;
  font-weight: bold;
  color: #777;
  height: 40px;
}

.root > div > div {
  padding-top: 10px;
  text-indent: 15px;
}

.root > div > div:nth-child(6) {
  text-indent: 0px;
  text-align: center;
  padding-top: 5px;
}

.bottom {
  border-bottom: 2px solid #056b90;
}

.link {
  color: #44a2d5;
  cursor: pointer;
}
