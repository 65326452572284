.root {
  display: flex;
  padding: 50px;
}

.column {
  color: #282828;
  width: 33%;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pair {
  margin-bottom: 20px;
}

.pair .type {
  font-size: 16px;
  font-weight: bold;
}

.pair .value {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.details {
  margin-top: -210px;
  text-align: center;
}

.details .value {
  font-size: 55px;
}

.details .text {
  font-weight: bold;
  font-size: 14px;
  margin-top: -10px;
}

.details .button {
  display: flex;
  justify-content: center;
  margin-top: 75px;
  cursor: pointer;
}
