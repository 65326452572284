.root {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.popup {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 482px;
}

.popup .top {
  height: 42px;
  background-color: #ccecf2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  box-sizing: border-box;
}

.popup .top .title {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
}

.popup .top .close {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 7px;
  cursor: pointer;
}

.popup .body {
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 20px;
}

.popup .body .title {
  font-size: 14px;
  color: #777;
  font-weight: bold;
}

.popup .body input,
.popup .body textarea {
  background-color: #eee;
  border: 0 none transparent;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 2px 2px 3px rgb(0 1 1 / 30%) inset;
  margin-top: 5px;
  margin-bottom: 5px;
}

.popup .body input:focus,
.popup .body textarea:focus {
  outline-width: 0;
}

.popup .body input {
  text-indent: 10px;
}

.popup .body .to {
  width: 95%;
  height: 35px;
}

.popup .body .contents {
  width: 95%;
  height: 90px;
}

.popup .body .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  line-height: 35px;
}

.popup .body .buttons .cancel {
}

.popup .body .buttons .send {
  border-radius: 6px;
  background: #ccc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  width: 110px;
  text-align: center;
}
