.root {
}

.unit {
  display: flex;
  margin-left: 50px;
  font-size: 18px;
  line-height: 40px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.title {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.text {
}
