.root {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 43px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 12px;
  background: rgba(255, 255, 255, 0.1);
}

.header .left {
  display: flex;
}

.header .name {
}

.header .divider {
  width: 13px;
  height: 43px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
}

.header .priority {
  display: flex;
  color: #056b90;
  font-size: 14px;
  margin-right: 20px;
}

.header .priority span {
  margin-left: 5px;
}

.choose {
  display: flex;
  font-size: 32px;
  font-weight: bold;
  padding-left: 30px;
  height: 69px;
  line-height: 69px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.choose .text {
  margin-right: 10px;
}

.choose .divider {
  margin-left: 10px;
  margin-right: 10px;
}

.skill {
  margin-left: 5px;
  font-size: 18px;
  display: inline;
}

.standard {
  display: flex;
}

.standard > div:nth-child(2) {
  margin-top: 3px;
  margin-left: 7px;
}

.standard.sub > div:nth-child(2) {
  margin-top: -2px;
  margin-left: 7px;
}
