.root {
  width: 100%;
  height: 100%;
}

.header {

  font-family: Open Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;


}

.contents {
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.rightHeader {
  float: right;
}