.root {
  padding-left: 10px;
  padding-right: 5px;
}

.header {
  padding-top: 15px;
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header a {
  color: #056b90;
  cursor: pointer;
  font-size: 16px;
}

.header a img {
  position: relative;
  top: 2px;
  left: -2px;
}

.contents {
  display: flex;
}

.left {
  width: 70%;
  margin-right: 50px;
}

.left .row {
  display: flex;
  margin-bottom: 20px;
  margin-left: -5px;
  margin-right: -5px;
}

.left .row > div {
  margin-left: 5px;
  margin-right: 5px;
}

.left .row .text {
  position: absolute;
  font-size: 78px;
  font-weight: bold;
  right: 20px;
  top: -15px;
}

.right {
}

.right > div:nth-child(1) {
  margin-bottom: 20px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.assignmentSettings {
  width: 576px;
  background-color: #fff;
  min-height: 20px;
  position: absolute;
  top: 30px;
  left: calc(50% - 286px);
  border-radius: 6px;
  font-family: ZillaSlab;
  padding-top: 0px;
}

.assignmentHeader {
  background-color: #ccecf2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 10px 10px 0px 20px;
  min-height: 30px;
}
.assignmentHeader h1 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 1em;
}
.assignmentHeader p {
  margin-top: 5px;
  display: inline-block;
  width: 400px;
  font-size: 14px;
}

.assignmentBody {
  padding: 20px;
}

.assignmentSection {
  border-bottom: 1px solid #056b90;
  margin-bottom: 15px;
  position: relative;
}

.assignmentSection p {
  display: block;
  width: 400px;
  font-size: 14px;
}

.assignmentFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px 20px 20px;
  gap: 10px;
  align-items: center;
}

.assignmentFooter .saveButton {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;

  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  padding: 12px 40px 10px 39px;
  box-shadow: none;
  border-style: none;
  cursor: pointer;
}

.assignmentFooter .cancelButton {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  text-align: right !important;
  direction: ltr;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  color: #056b90;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 22px;
  margin-left: 5px;
  margin-top: 0;
  background: none;
  border: none;
  box-sizing: border-box;
  padding-top: 20px;
}
.closeButton {
  position: absolute;
  right: 10px;
  top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.onButton,
.offButton {
  width: 60px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.offButton {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAdCAYAAADy+d/cAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAPSSURBVFhH7ZhpSBVRGIbfDFtc0TTLFkuybFE0KJFCy7TtRy4Qaf2obKNFgigK9GdFUQRiFO0JlUXg8qOFNEsxRIUUbbEMSyvLtMS1jZZ5DzPjveN4vV6Sa90eGK7nnDnIc5bvfGeG/JKADWIn/9ocNitu1lKvaW5BZmUNCmtf4/H7ZjR1dOHHT+vukKF2Q+Dp5ICZYzwQ5jsBcYF+8PNwk1v7xqQ4hffnFiP32SusCvLHIj8fBI71hJezo/jH1oQD39jeicp3TbhbU4drFdWImjYJKVGhZg1Ar+LnS6qQlJWHlMhQ7AqfY3XRvuBAHCsow/68YqTFRiIxJEBu0UdX/HB+Ka6WP8XplYsRPM5Lrv07KH/biM3X7yA+eDr2RsyVa3vSQ5wznVb0EDnrY+Ht6iTXAqX1Dbj8sBoF0j5/9bFV1E0a5YpwaX+tme2PuRO9Rd1goKG1A9EXspA0f3avM28kzj0ddPQi7m+LN5rpndn5OFdSKZf02RASiNSYCLlkfTjzC05cRcXudbp73kh8bcZN+Hu6Y8/C7iUScz4LeVLwMIdIKfhlJ8bKJetz5F4pqps+IT1huVzTjSrO2Q4/noGXyVvUQGbOTGsxNfON7V1YkpaJ540touzhNBI3tscgwNtDlHOr6xF9Mkf8bUjO1mjx21tblP9EuWQMA97kA6dQsCOhx6yrCQzPaR5ZijT3dH+lCfuwr5aqhmbMOXRFlSbNHZ8RcjhDCA8EdKET3bSo4kxOeE4rMJBZil7fM0VVQnTjvFnoSk0Sz9G4MNG2J7NQrAYFw3f4GM6oqTY96EQ3Lao4MzImJwqM3pai7UupwhdvMdXLDcnLQuRaKaaEzsDSGT741PkFHwzE/yR0opsWVZxpKDMyBeXIsgRtX0pRzneUC5yG28u1gOMwe4x3cxYr4X1bt/jZB4/gsDNNPEEHLxmtBlNtetCJbloG/SVFO1iGmGrrC1WcCT9zXwUmJ5ai7Tva2QHujiNQ+7ENHV+/y7VA57fveNPSLqL7GBcHudZ4H2duWSFWhoKpNj3oRDctqjhvOUz4FZiRWYq2r5ckHjZlnIjoB26VyLVAevET3H5SJwaFgzMQ0IluWlRxXu14y1FgGmopen03zQ8QM2u4R3dL0ZwckaI7B2cgoBPdtKjivM/yaqfcs5l7MxnpL+yjl7czSSnbt1pEdgUORMnehD6PJEuhC53opuWPp6xZUso6WC6wplJWo6jOSzzvs0zwFZh7mzPzfIfvDhZpOtCFTnr8v5YaYpMfIhRs8tOTgk1+bDTE5j4v/8sYHWe2A/Abec84ALACUvEAAAAASUVORK5CYII=);
}
.onButton {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAAeCAYAAACfWhZxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAObSURBVFhH7ZjvS1NRGMcfNZzV1HSCImqmTqxREv7oVa3U3qSS2ouKUMrqTQRJEtZfUBJKQfSqLAwpiFRQgyij2at0EiWz0DQzEQW11BFOsjrf493l3uM2dwfhcn1A7s659+A+e845z3Nu0G8GBRjB0jWgCEhpr6b34NQ3an4/SG22TzQwNUP2hUX6tcaLIjiISB8WSukx0VRsSqOyXUYyxkRJdz3jURqyNe0W6hz4QsSeciwtSXf8C11ICDMhyk/fSrVF5lXl3Uo3vOmj883P1zyiWsEMuFV2kCr37JR6VuJyTde+7KbLHV3/nDDAd8Z3h4M7VkQaEcaguQWH1OMf5CbF0YksE5lTEig5OpL3jczMkmV4jJp6bdQ9OsH7nESE6eha4T6XEVdJYw1nXr/ndxG+WZpPp3PdT1dwt7uPLrR0Sq1lMNXfXTq1Yo2rpjc2LX8Tbq0sWVUY4Bk8qwQucBKRI40oZ9c3kuOn9h36Nts4KnJMUotofNZOZx8/I8vQV962VpVTRqxB1X9893aqP3yA5ln6Uz6rxJsIi4gR120IIevFClW05UgjDyMtaaX9zBGVMIiP1NOj8mIupgT9RzMzpJZnsIa1CgOMwVgZ5sTdFMjSKDy05mFIZSXE8s+NPTbSX7lBhXee8IiG60Kp2pzN7ykpYLnUnJootdyDTctXlGPhBDclsjQqLa3s3ZbA5T5OTtM5ltMBpmmj1UaL7J9hB3UKoo3d1ttoY5f2FXGs6CZLo7TUSlJ0BL9O2H/wq5Oh6e98bwhnZWJ8hF7qJeoaGqN5xyKVspIxxbBF6nWNMy35gjhWdFPt3n+b8Tk79Y5N8tmRZ0zkm9haIEujeNfK6Mwcv8bpN/Grk1QWReyakIKokqbefh7tNEMUf8YdWAq+Io4V3WRpnFa08vrz8nRFOkLaAljDFdkmCmWHAFR1Yip6+PYDj7Zh80b+5w5UWr4ijhXdZGkcz/hpRQNOAYC0Zb9aRR0shWGzwo9RZ7HyeyLOaHsCpaWvKMfCCW5KZGmcR3E800oRS1FIV0qQso49aOM/iivQ3yLkThHU0ig0tIIxqjqcOXE3Barau+x+Kz3tH5Za/gFKywJjstTyzIvBESppaJVayxzakULNJ9XlqWr3xgEcRbo/AQlvIo5nRGG4wEnk/9HSCQ7gda96/E7cWyBcvT+HavJypR41LqXBen5d5FYaBNyLQSUB9Qp4vaJKWYFCAEoT/QF0ottMgY2qqgAAAABJRU5ErkJggg==);
}

.purpleMessage {
  color: #ab0049;
}
