.root {
  padding: 20px;
  height: 100%;
  position: relative;
}

.top {
  display: flex;
  flex-direction: row-reverse;
}

.details {
  font-size: 15px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  font-size: 13px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #5a5a5a;
}

.students {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.back {
  position: absolute;
  top: 10px;
  left: 20px;
}