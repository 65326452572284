.root {
  width: 100%;
  display: flex;
}

.contents {
  width: 100%;
}

.header {
  display: flex;
  color: #282828;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: bold;
  padding-left: 50px;
  height: 43px;
  line-height: 43px;
}

.header > div:nth-child(1) {
  margin-right: 5px;
}

.divider {
  width: 13px;
  height: 43px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  padding-left: 50px;
  height: 69px;
  line-height: 69px;
  width: 100%;
  display: flex;
}
