.root {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border-style: 1px solid #777;
  background: white;
  cursor: pointer;
}

.selected {
  background: #0075ff;
}
