.root {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 6px;
  cursor: pointer;
  vertical-align: bottom;
}

.root:hover {
  color: #f89d02;
}

.image {
}

.image img {
  width: 100%;
}

.title {
}
