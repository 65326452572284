@font-face {
  font-family: "Soleil";
  src: local("Soleil"), url("./fonts/Soleil.otf") format("opentype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("../../common/fonts/Proxima Nova Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("../../common/fonts/Proxima Nova Bold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"),
    url("./fonts/ZillaSlab-Regular.ttf") format("truetype");
}

@import url("https://use.typekit.net/zey2wnk.css");
