.root {
  display: flex;
  min-height: 105px;
  width: 100%;
  padding-bottom: 10px;
}

.type {
  display: flex;
  flex-direction: column;
  width: 75px;
  margin-left: 20px;
  margin-top: 20px;
}

.type .icon {
  width: 80%;
}

.type .icon img {
  width: 100%;
}

.type .text {
  font-size: 14px;
  width: 60px;
  text-align: center;
}

.description {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  margin-top: 10px;
  margin-left: 10px;
}

.count {
  font-size: 14px;
  margin-top: 10px;
}

.divider {
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}
