.container {
  display: inline-block;
  width: 105px;
  position: relative;
  left: 5px;
}
.nameDropdown {
  padding: 25px 0px 0px 0px;
  font-size: 14px;
  text-align: center;
  width: 77px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
}

.nameDropdown:hover {
  color: #f89d02;
}

.nameDropdown:after {
  background: url(./header-dropdown-arrow.png) no-repeat;
  border-style: none;
  content: "";
  height: 7px;
  position: absolute;
  right: 10px;
  top: 32px;
  width: 11px;
}

.dropdown {
  background: #282828;
  color: #fff;
  width: 158px;
  top: 70px;
  right: calc(50vw - 492px);
  margin: 0;
  text-align: left;
  border: none;
  font-family: AkagiPro-Medium;
  position: absolute;
  z-index: 50000;
}
