.root {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.root > .title {
  font-family: ZillaSlab;
  font-size: 20px;
  color: #777;
}

.desc {
  font-size: 13px;
  color: #777;
  margin-bottom: 20px;
}

.dropdowns {
  display: flex;
  justify-content: space-between;
}

.dropdowns .left {
}

.dropdowns .right {
  display: flex;
}

.dropdowns .right .show {
  margin-right: 10px;
}

.tabs {
  color: #777;
  font-size: 14px;
}

.tabs .blue {
}

.contents {
  margin-top: 30px;
  margin-bottom: 50px;
}

.contents .row {
  display: flex;
}

.contents .row > div:nth-child(1) {
  width: 42px;
}

.contents .row > div:nth-child(2) {
  width: 208px;
}

.contents .row > div:nth-child(3) {
  width: 150px;
}

.contents .row > div:nth-child(4) {
  width: 175px;
}

.contents .row > div:nth-child(5) {
  width: 250px;
}

.header {
  font-weight: bold;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.header:nth-child(3) {
}

.header:nth-child(4) {
}

.box {
  font-size: 16px;
  color: #282828;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box:nth-child(3) {
}

.box:nth-child(4) {
}

.box .title {
  font-size: 16px;
  color: #282828;
}

.box .category {
}

.box:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box:nth-child(4) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
