
.root
{
    display: flex;
    cursor: pointer;
}


.text {
    color: #0a86cb;
    font-size: 13px;
    margin-left: 2px;
    margin-top: 2px;
  }