
.root {
    width: 100%;
    height: 100%;
}

.contents {    
    overflow-y: auto;
    height: Calc(100% - 40px);
}

.row {
    width: 100%;
    height: 40px;
    display: flex;
}

.row > div:nth-child(1)
{
    width: 200px;
    min-width: 200px;
}
.row > div:nth-child(2)
{
    width: 125px;
    min-width: 125px;
}
.row > div:nth-child(3)
{
    width: 125px;
    min-width: 125px;
}
.row > div:nth-child(4)
{
    width: 125px; 
    min-width: 125px;
}
.row > div:nth-child(5)
{
    width: 125px;
}

.header {
    background: #EDF6F9;
    border-bottom: 2px solid #DCEEF3;
    border-right: 1px solid #DCEEF3;
    color: #5a5a5a;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content {
    border-top: 1px solid #7AC0D1;
    border-bottom: 1px solid #7AC0D1;
    border-right: 1px solid #DCEEF3;
    color: #282828;
    font-size: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content:nth-child(1)
{
    color: #056B90;
    text-align: left;
}