.root {
  width: 100%;
}

.contents {
  width: 1200px;
  min-height: Calc(100vh - 78px);
  margin-left: auto;
  margin-right: auto;
  
  position: relative;
  overflow-x: hidden;
}
