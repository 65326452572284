.root {
}

.dropdown {
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
  user-select: none;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: #282828;
  cursor: pointer;

  background: white;
  border: 1px solid #c9b6cc;
  box-sizing: border-box;
}

.popup {
  position: absolute;
  z-index: 10000;
}

.dropdown-header {
}

.dropdown-text {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
  white-space: nowrap;
}

.dropdown-arrow {
  color: #131313;
  font-size: 20px;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.menupopup,
.submenupopup {
  background: white;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.submenupopup {
  margin-top: -5px;
  margin-left: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  cursor: pointer;
  background: white;
  font-size: 13px;
  color: #282828;
}

.item:first-child {
}

.item:last-child {
}

.text {
  font-size: 13px;
  color: #282828;
  margin-right: 0px;
}

.selected {
  background: #1a8ece;
}

.selected .text {
  color: white;
}

.arrow {
  margin-left: 20px;
}

.disabled {
  color: rgba(255, 255, 255, 0.5);
}
