.root {
  display: flex;
  margin-bottom: 5px;
}

.description {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  margin-top: 10px;
  margin-left: 10px;
}

.count {
  font-size: 14px;
  margin-top: 10px;
}
