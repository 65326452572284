.root {
}

.instructions {
  font-size: 14px;
  color: #666666;
}

.assignments {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  border-bottom: 2px solid #056b90;
  display: flex;
}

.row > div {
  padding: 10px;
}

.row > div:nth-child(1) {
  min-width: 250px;
  width: 250px;
  border-right: 2px solid #056b90;
}

.row > div:nth-child(2) {
  min-width: 90px;
  width: 90px;
  border-right: 2px solid #056b90;
}

.row > div:nth-child(3) {
  min-width: 90px;
  width: 90px;
  border-right: 2px solid #056b90;
}

.row > div:nth-child(4) {
  min-width: 126px;
  width: 126px;
  border-right: 2px solid #056b90;
}

.row > div:nth-child(5) {
  min-width: 70px;
  width: 70px;
}

.row .header {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.row .box {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  background: #ffff99;
}

.row .box:nth-child(1) {
  color: #666666;
}

.row .box:nth-child(2) {
  color: #056b90;
}

.row .box:nth-child(3) {
  color: #056b90;
}

.row .box:nth-child(4) {
  color: #056b90;
}

.row .box .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.row .box .class {
  cursor: pointer;
  display: flex;
}

.row .box .pencil {
  margin-top: 8px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png?v=3.0)
    no-repeat scroll -174px -586px rgba(0, 0, 0, 0);
  width: 18px;
  height: 18px;
  margin-right: -4px;
}

.row .box .class:hover .pencil {
  background-position: -174px -612px;
}

.row .box .trash {
  margin-top: 4px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -176px -1336px rgba(0, 0, 0, 0);
  width: 27px;
  height: 27px;
  margin-left: 25px;
  cursor: pointer;
}

.row .box .trash:hover {
  background-position: -176px -1363px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  height: 70px;
  border-top: 1px solid #056b90;
  padding-top: 30px;
  box-sizing: border-box;
}

.bottom .cancel {
  color: #056b90;
  font-size: 18px;
  width: 70px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.bottom .cancel:hover {
  color: #f89d02;
}

.bottom .assign {
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  height: 30px;
  line-height: 30px;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.bottom .assign:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.buttons .update {
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 14px;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  cursor: pointer;
}

.buttons .update:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}
