.tip {
  position: fixed;
  left: 15px;
  bottom: 25px;
  width: 500px;
  pointer-events: all;
  font-family: ProximaNova;
}

.animate {
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-play-state: running;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.925);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-height: 800px) {
  .tip {
    bottom: 60px;
  }
}
