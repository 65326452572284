.root {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px;
  padding-bottom: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.left {
  display: flex;
  width: 250px;
}

.icon {
  margin-top: -7px;
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  font-size: 14px;
}

.right {
}

.button {
  cursor: pointer;
}
