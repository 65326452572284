.root {
  display: flex;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 20px;
}

.first {
  font-weight: bold;
  font-size: 17px;
  background: rgba(255, 255, 255, 0.5);
}

.index {
  margin-right: 5px;
  width: 20px;
}

.student {
  width: 300px;
}

.value {
}
