.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.popup {
  position: absolute;
  color: #282828;
  font-size: 14px;
  padding: 20px;
  width: 905px;
  height: 550px;
  left: Calc(50% + 15px);
  transform: translateX(-50%);
  top: 278px;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 6px 0 rgb(0 0 0 / 30%);
  display: flex;
  justify-content: space-between;
  background: #ccecf2;
}

.background {
  position: absolute;
  left: 300px;
  width: Calc(100% - 300px);
}

.contents {
  width: 875px;
  position: absolute;
}

.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.top .left {
  font-size: 22px;
  font-weight: bold;
}

.top .right {
  font-size: 14px;
  display: flex;
}

.top .right .text {
  margin-top: 3px;
  cursor: pointer;
}

.top .right .close {
  width: 21px;
  height: 21px;
  background: #fff;
  color: black;
  text-align: center;
  line-height: 21px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}
