.root {
  width: 21px;
  height: 21px;
  color: black;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 10px;
}

.root.selected {
  background: black;
  color: white;
}
