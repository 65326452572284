.root {
  padding: 20px;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.split {
  display: flex;
  color: #5a5a5a;
}

.divider {
  width: 1px;
  height: 250px;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
  background: #309acf;
}
