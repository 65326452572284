.root {
  width: 30px;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: Calc(100vh - 145px);
  background: #009DD1;
  margin-top: 20px;

}

.button {
  color: white;
  background: url("./leftArrow.png") no-repeat;
  background-position: center;
  height: 40px;
}

.link {
  margin-top: 10px;
  transform: rotateZ(90deg);
  white-space: nowrap;


  color: "#fff";

  /* UI Text/Small 2 - Open Sans Bold 14pt */
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.root.dark {
  background: #009DD1;
}

.shadow {
  box-shadow: -5px 0 5px -3px rgba(0, 0, 0, 0.25);
}