.background {
  height: 8px;
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
}
