.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
}

.popup {
  box-sizing: border-box;
  width: 760px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: Calc(50% + 50px);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
}

.popup .titlebar {
  width: 100%;
  background: #ccecf2;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}

.popup .top {
  width: 100%;
  height: 150px;
  background: #ccecf2;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.popup .titlebar .header,
.popup .top .header {
  display: flex;
  justify-content: space-between;
}

.popup .top .header .title {
  font-size: 18px;
  font-weight: bold;
}

.popup .top .header .close {
  cursor: pointer;
}

.popup .top .subheader {
  display: flex;
  justify-content: space-between;
}

.popup .top .tabs {
  position: absolute;
  bottom: 0;
  left: 25px;
  display: flex;
}

.popup .content {
  width: 100%;
  box-sizing: border-box;

  padding: 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.popup .bottom {
  width: 100%;
  height: 390px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
