
.button {
  color: white;
  font: 400 16px Arial;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  background-color: #328ecc;
  border-radius: 3px;
  border: none;
  width: 104px;
  height: 32px;
  line-height: 34px;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  margin-left: 10px;
  user-select: none;
}

.button:hover {
  background-color: #126ebc;
}