.root {
  height: 100%;
}

.root > .top {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.root > .top .title {
  font-size: 22px;
  font-weight: bold;
  font-family: ZillaSlab;
}

.top .button {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #dbf2f6;
  color: #6d797b;
  border-radius: 11px;
}

.main {
  display: flex;
  height: Calc(100% - 3px);
}

.main .bar {
  background: #d3e4e7;
  width: 36px;
  min-width: 36px;
  height: 100%;
  margin-right: 36px;
}

.content {
  width: 100%;
  margin-right: 50px;
  margin-left: 40px;
  height: 100%;
}

.scroll {
  max-height: 80%;
  height: 80%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content .top {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.content .selections {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.content .selections .tabs {
  display: flex;
}

.content .selections .tabs .button {
  width: 270px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #056b90;
  background: #b7d4d9;
  text-align: center;
}

.content .selections .tabs .button.selected {
  color: black;
  background: #def3f7;
}

.content .space {
  width: 898px;
  height: 40px;
  background: #e0f7fb;
  border: 1px solid #c2dfe4;
}

.spacer {
  flex-grow: 1;
}
.updateLabel {
  padding-top: 7px;
  font-size: 12px;
}
.content .headers {
  height: 100px;
  display: inline-flex;
  background: #d3e4e7;
  border-bottom: 2px solid #d3e4e7;
}

.content .headers .header {
  width: 148px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-transform: uppercase;
}

.content .row {
  background: #d3e4e7;
  display: inline-flex;
  height: 38px;
  line-height: 38px;
  padding-bottom: 2px;
}

.content .row.first {
  margin-top: -2px;
}

.content .row .box {
  width: 148px;
  height: 100%;
  background: #def3f7;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 16px;
  text-align: center;
}

.content .row .box:nth-child(1) {
  font-size: 12px;
  text-indent: 15px;
  text-align: left;
}

.content .category {
  background: #d3e4e7;
  height: 70px;
  display: inline-flex;

  padding-bottom: 2px;
}

.content .category .type {
  width: 148px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-align: left;
  text-indent: 15px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
}

.text {
  font-size: 13px;
}

.content .category .box {
  width: 148px;
  height: 100%;
  background: #def3f7;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.footer .trend {
  display: flex;
}

.footer .trend .icon {
  margin-right: 10px;
}

.footer .trend .text {
  margin-top: 2px;
  font-size: 13px;
}

.how {
  position: absolute;
  top: 133px;
  left: 45px;
  overflow-y: auto;
  max-height: Calc(100% - 76px);
  z-index: 999999999;
}

.how .close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
