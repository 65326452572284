.groupPrompt {
  background-color: #fff;
  box-sizing: border-box;
  position: absolute;
  width: 340px;

  top: 100px;
  left: calc(50% - 170px);
  border-radius: 6px;

  font-family: ZillaSlab;
}

.groupPrompt .header {
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #ccecf2;
  height: 43px;
  font-size: 20px;
  font-weight: bold;
  position: relative;
}

.groupPrompt .header .closeButton {
  position: absolute;
  right: 10px;
  top: 5px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.groupPrompt .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
}
.groupPrompt .body {
  font-weight: bold;
  background-color: #fff;
  color: #777;
  padding: 20px 15px;
}

.groupPrompt input {
  margin-top: 20px;
  -webkit-text-size-adjust: 100%;
  user-select: none;
  visibility: visible;
  direction: ltr;
  line-height: normal;
  font-weight: 400;
  font-family: inherit;
  display: block;

  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  background-color: #eee;
  border: 0 none transparent;
  border-radius: 5px 5px 5px 5px;
  -webkit-box-shadow: 2px 2px 3px rgba(0, 1, 1, 0.3) inset;
  color: #666;
  font-size: 14px;
  height: 35px;
  -webkit-appearance: none;
  outline: none;
}

.groupPrompt .cancelButton {
  background: none;
  border: none;
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  text-align: right !important;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  color: #449dac;
  margin-right: 13px;
  cursor: pointer;
}

.groupPrompt .continueButton {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  box-shadow: none;
  border-style: none;
  padding: 10px 19px 10px 19px;
}

.groupPrompt .continueButton:disabled {
  -webkit-text-size-adjust: 100%;
  cursor: pointer;
  font-style: normal;
  user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  background: #ccc !important;
  color: #fff !important;
  display: inline-block;

  border-radius: 8px;
  margin: 2px 0 0;
  box-shadow: none;
  border-style: none;
  padding: 10px 19px 10px 19px;
}
