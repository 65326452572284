@import url(https://use.typekit.net/zey2wnk.css);
.App_app__2qY7E {
  font-family: OpenSans;
  width: 100%;
  height: 100%;
  background-color: #FBFCFD;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App_wrapper__38CLC {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.App_subwrapper__V-Mbi {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.App_right__3cJl8 {
  width: Calc(100% - 175px);
  margin-left: 175px;
}

@media screen and (min-width: 768px) {
  .App_right__3cJl8 {
    width: Calc(100% - 205px);
    margin-left: 205px;
  }
}

@media screen and (min-width: 1200px) {
  .App_right__3cJl8 {
    width: Calc(100% - 240px);
    margin-left: 240px;
  }
}

.tooltip_tooltip__2hdUL {
  top: 5px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 15px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  color: #444547;
  box-sizing: border-box;
  min-width: 200px;
  font-family: ProximaNova;
  border-top: 4px solid #2191cf;
}

.tooltip_title__2V-7L {
  font-weight: bold;
  margin-bottom: 10px;
}

.tooltip_text__Lutzx {
}

.tooltip_arrow__16M03 {
}

.TutorialTipToggle_root__YZ0Mv {
  position: fixed;
  pointer-events: all;
  width: 100px;
  background: white;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid black;
  margin: 2px;
  margin-left: 10px;
  font-family: ProximaNova;
  z-index: 9999999999999;
  height: 15px;
  line-height: 15px;
}

.TutorialTipToggle_contents__1H_mj {
  position: relative;
  display: flex;
}

.TutorialTipToggle_checkbox__1GpOe {
  margin-top: -8px;
  margin-right: 10px;
  height: 30px;
}

.Header_background__1kk26 {
  height: 55px;
  display: flex;
  align-items: center;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 15px;
}

.Header_text__2J5Yi {
  font-family: ProximaNovaBold;
  font-size: 22px;
  font-weight: 800;
}

.Header_x-button__3jsWT {
  position: absolute;
  top: 7px;
  right: 15px;
  font-weight: 900;
  cursor: pointer;
  font-size: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  font-family: ProximaNovaBold;
}

.Header_x-button__3jsWT:hover {
  top: 0px;
  right: 10px;
  font-size: 40px;
  /*transform: scale3d(1.27, 1.27, 1.27);*/
}


.Button_button__PjJnk {
  color: white;
  font: 400 16px Arial;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  background-color: #328ecc;
  border-radius: 3px;
  border: none;
  width: 104px;
  height: 32px;
  line-height: 34px;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  margin-left: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Button_button__PjJnk:hover {
  background-color: #126ebc;
}
.Body_background__3Wv8S {
  width: 100%;
  padding: 35px;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #444547;
  box-sizing: border-box;
  position: relative;
}

.Body_text__3E372 {
  margin-bottom: 15px;
}

.Body_buttons__37SRg {
  display: flex;
  flex-direction: row-reverse;
}

.Popup_popup__2qI6v {
  position: fixed;
  width: 50%;
  max-width: 720px;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  -webkit-transform: translate(0%, -40%);
          transform: translate(0%, -40%);
  pointer-events: all;
  font-family: ProximaNova;
}

.Popup_animate__2ldb2 {
  -webkit-animation-name: Popup_fadeIn__1uHpG;
          animation-name: Popup_fadeIn__1uHpG;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes Popup_fadeIn__1uHpG {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Popup_fadeIn__1uHpG {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Header_background__1EWKS {
  height: 8px;
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
}


.XButton_x-button__3_1Wb {
  position: absolute;
  top: 3px;
  right: 10px;
  color: rgb(132, 132, 132);
  font-family: Soleil, Verdana, Geneva, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Body_background__2A3Im {
  width: 100%;
  padding: 15px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  color: #444547;
  box-sizing: border-box;
  position: relative;
}

.Body_title__3aFfK {
  font-weight: bold;
  font-size: 19px;
  font-family: ProximaNovaBold;
}

.Body_text__2w_hj {
  margin-top: 10px;
  margin-bottom: 15px;
}

.Body_buttons__2I8L7 {
  display: flex;
  flex-direction: row-reverse;
}

.Tip_tip__2uVNv {
  position: fixed;
  left: 15px;
  bottom: 25px;
  width: 500px;
  pointer-events: all;
  font-family: ProximaNova;
}

.Tip_animate__1OD6D {
  -webkit-animation-name: Tip_bounce__1SQl0;
          animation-name: Tip_bounce__1SQl0;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes Tip_bounce__1SQl0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  25% {
    -webkit-transform: scale(0.925);
            transform: scale(0.925);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  75% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes Tip_bounce__1SQl0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  25% {
    -webkit-transform: scale(0.925);
            transform: scale(0.925);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  75% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-height: 800px) {
  .Tip_tip__2uVNv {
    bottom: 60px;
  }
}

.Outter_contents__1RQLF {
  top: 110px;
  width: 100%;
  height: Calc(100% - 78px);
  background-color: #fbfcfd;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 999999;
}

.Icon_root__22ca5 {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 6px;
  cursor: pointer;
  vertical-align: bottom;
}

.Icon_root__22ca5:hover {
  color: #f89d02;
}

.Icon_image__17PVL {
}

.Icon_image__17PVL img {
  width: 100%;
}

.Icon_title__oDVbU {
}

.Subject_root__1y7pZ {
  font-size: 14px;
  color: #056b90;
  cursor: pointer;
  font-weight: bold;
}

.Subject_root__1y7pZ:hover {
  color: #f89d02;
}

.Subject_selected__3H-7E,
.Subject_selected__3H-7E:hover {
  color: black;
  pointer-events: none;
}


.Message_root__1SS4Z {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Message_popup__2Yk3d {
  position: absolute;
  left: 50%;
  top: 100px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 482px;
}

.Message_popup__2Yk3d .Message_top__saBKP {
  height: 42px;
  background-color: #ccecf2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  box-sizing: border-box;
}

.Message_popup__2Yk3d .Message_top__saBKP .Message_title__UihNi {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
}

.Message_popup__2Yk3d .Message_top__saBKP .Message_close__2wQZd {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 7px;
  cursor: pointer;
}

.Message_popup__2Yk3d .Message_body__FAicQ {
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 20px;
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_title__UihNi {
  font-size: 14px;
  color: #777;
  font-weight: bold;
}

.Message_popup__2Yk3d .Message_body__FAicQ input,
.Message_popup__2Yk3d .Message_body__FAicQ textarea {
  background-color: #eee;
  border: 0 none transparent;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 2px 2px 3px rgb(0 1 1 / 30%) inset;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Message_popup__2Yk3d .Message_body__FAicQ input:focus,
.Message_popup__2Yk3d .Message_body__FAicQ textarea:focus {
  outline-width: 0;
}

.Message_popup__2Yk3d .Message_body__FAicQ input {
  text-indent: 10px;
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_to__AOqvn {
  width: 95%;
  height: 35px;
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_contents__2iGwd {
  width: 95%;
  height: 90px;
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_buttons__2DLc2 {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  line-height: 35px;
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_buttons__2DLc2 .Message_cancel__2bMsh {
}

.Message_popup__2Yk3d .Message_body__FAicQ .Message_buttons__2DLc2 .Message_send__2ai8t {
  border-radius: 6px;
  background: #ccc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  width: 110px;
  text-align: center;
}

.user-menu_container__1MFos {
  display: inline-block;
  width: 105px;
  position: relative;
  left: 5px;
}
.user-menu_nameDropdown__zUSd3 {
  padding: 25px 0px 0px 0px;
  font-size: 14px;
  text-align: center;
  width: 77px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
}

.user-menu_nameDropdown__zUSd3:hover {
  color: #f89d02;
}

.user-menu_nameDropdown__zUSd3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAAgUlEQVQYlXXPMQoCUQyE4Vcri97BUtjCa9jZCoLdFoqVxxFEBcHWq1h6A2tBFP0sfAthWQOBkPmHSRLGOGOE9KfnOCVs/OqCsgWc4Y53Qh/HYBgGcJrBD1b1ssA2GAaY4IEXFkgxroNdNlxxy3NVM837ujhk6Ill1Ns+72GPdVP7ArplAS1Di9jiAAAAAElFTkSuQmCC) no-repeat;
  border-style: none;
  content: "";
  height: 7px;
  position: absolute;
  right: 10px;
  top: 32px;
  width: 11px;
}

.user-menu_dropdown__2AYF3 {
  background: #282828;
  color: #fff;
  width: 158px;
  top: 70px;
  right: calc(50vw - 492px);
  margin: 0;
  text-align: left;
  border: none;
  font-family: AkagiPro-Medium;
  position: absolute;
  z-index: 50000;
}

.Contents_root__39-ki {
  width: 100%;
}

.Contents_contents__1747a {
  width: 1200px;
  min-height: Calc(100vh - 78px);
  margin-left: auto;
  margin-right: auto;
  
  position: relative;
  overflow-x: hidden;
}

.FindContent_root__3Z-ic {
  width: 100%;
  height: 100%;
}

.FindContent_header__m_XP1 {

  font-family: Open Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;


}

.FindContent_contents__UkV7l {
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.FindContent_rightHeader__3At8n {
  float: right;
}
.GradeButton_root__1S4Kj {
  width: 21px;
  height: 21px;
  color: black;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 10px;
}

.GradeButton_root__1S4Kj.GradeButton_selected__1AHay {
  background: black;
  color: white;
}

.GradeScreen_root__jydWj {
  width: 100%;
  display: flex;
}

.GradeScreen_contents__1AkmM {
  width: 100%;
}

.GradeScreen_header__3AKh7 {
  display: flex;
  color: #282828;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: bold;
  padding-left: 50px;
  height: 43px;
  line-height: 43px;
}

.GradeScreen_header__3AKh7 > div:nth-child(1) {
  margin-right: 5px;
}

.GradeScreen_divider__2d0-a {
  width: 13px;
  height: 43px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.GradeScreen_title__3y_n3 {
  font-size: 32px;
  font-weight: bold;
  padding-left: 50px;
  height: 69px;
  line-height: 69px;
  width: 100%;
  display: flex;
}

.Entry_root__1lXqD {
  width: 100%;
  height: 41px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 18px;
}

.Entry_highlight__1KR9U {
  background: rgba(255, 255, 255, 0.1);
}

.ResultsBar_root__23RSZ {
  width: 54px;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: Calc(100vh - 135px);
  background: rgba(255, 255, 255, 0.35);
}

.ResultsBar_link__3EtEo {
  margin-top: 55px;
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
  white-space: nowrap;
  font-size: 18px;
}

.ResultsBar_root__23RSZ.ResultsBar_dark__1ynXL {
  background: rgba(0, 0, 0, 0.1);
}

.LessonEntry_root__3FlJ8 {
}

.LessonEntry_unit__2KqZo {
  display: flex;
  margin-left: 50px;
  font-size: 18px;
  line-height: 40px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.LessonEntry_title__3yqne {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.LessonEntry_text__2WG7z {
}

.Arrow_root__NadBt {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.Arrow_selected__1TzGd {
  background: black;
}

.Arrow_root__NadBt img {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 1px;
}

.Arrow_root__NadBt.Arrow_selected__1TzGd img {
  margin-bottom: 2px;
}

.Module_root__57ZfF {
  width: 100%;
}

.Module_highlight__181GQ {
  background: rgba(255, 255, 255, 0.1);
}

.Module_module__pCQsl {
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.Module_module__pCQsl > div:nth-child(1) {
  margin-left: 55px;
  margin-top: 12px;
}

.Module_title__2jF4y {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 40px;
  font-size: 18px;
}

.Module_text__2Bvsk {
  line-height: 40px;
  font-size: 18px;
}

.Module_link__dlx1Z {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 40px;
  font-size: 18px;
}

.Module_link__dlx1Z > span {
  margin-left: 80px;
}

.DropDown_root__1pgWO {
}

.DropDown_dropdown__3GNQB {
  display: flex;
  justify-content: space-between;
  height: 25px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 2px;
  padding-right: 5px;
  font-size: 14px;
  color: #131313;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.34);
  box-sizing: border-box;
}

.DropDown_popup__3oA-p {
  position: absolute;
  z-index: 10000;
}

.DropDown_dropdown-header__3UKR2 {
}

.DropDown_dropdown-text__109ZH {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.DropDown_dropdown-arrow__WDW-p {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.DropDown_menupopup__6chsY,
.DropDown_submenupopup__2EiJY {
  background: #292929;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: white;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.DropDown_submenupopup__2EiJY {
  margin-top: -5px;
  margin-left: 10px;
}

.DropDown_item__2ig0M {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;

  border-top: 1px solid #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.DropDown_item__2ig0M:first-child {
  margin-top: 5px;
}

.DropDown_item__2ig0M:last-child {
  margin-bottom: 5px;
}

.DropDown_text__38LRN {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-right: 0px;
}

.DropDown_selected__lVvM6 {
}

.DropDown_selected__lVvM6 .DropDown_text__38LRN {
}

.DropDown_arrow__1P09A {
  margin-left: 20px;
}

.DropDown_disabled__2slvq {
  color: rgba(255, 255, 255, 0.5);
}

.DropDownGrowth_root__t111A {
}

.DropDownGrowth_dropdown__1afsp {
  display: flex;
  justify-content: space-between;
  height: 35px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  color: #131313;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #7ac0d1;
  box-sizing: border-box;
}

.DropDownGrowth_popup__1RRRN {
  position: absolute;
  z-index: 10000;
}

.DropDownGrowth_dropdown-header__2icfZ {
}

.DropDownGrowth_dropdown-text__1N1Qf {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.DropDownGrowth_dropdown-arrow__3wtC5 {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.DropDownGrowth_menupopup__2IsNx,
.DropDownGrowth_submenupopup__3Y10c {
  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #7ac0d1;
  box-sizing: border-box;
  color: #131313;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.DropDownGrowth_submenupopup__3Y10c {
  margin-top: -5px;
  margin-left: 10px;
}

.DropDownGrowth_item__34fTS {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #7ac0d1;
  cursor: pointer;
  background: #e0f4f7;
}

.DropDownGrowth_item__34fTS:first-child {
}

.DropDownGrowth_item__34fTS:last-child {
}

.DropDownGrowth_text__2ykQc {
  font-size: 16px;
  color: #131313;
  text-align: center;
  margin-right: 0px;
}

.DropDownGrowth_selected__3uFZN {
  background-color: white;
}

.DropDownGrowth_selected__3uFZN .DropDownGrowth_text__2ykQc {
}

.DropDownGrowth_arrow__3uHwV {
  margin-left: 20px;
}

.DropDownGrowth_disabled__3FoUS {
  color: rgba(255, 255, 255, 0.5);
}

.DropDownMindset_root__1fVCm {
}

.DropDownMindset_dropdown__1euwp {
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  cursor: pointer;

  background: #eeeeee;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  border-radius: 5px;
}

.DropDownMindset_popup__UkBcf {
  position: absolute;
  z-index: 10000;
}

.DropDownMindset_dropdown-header__3HHJu {
}

.DropDownMindset_dropdown-text__2DoD9 {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.DropDownMindset_dropdown-arrow__3VE27 {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.DropDownMindset_menupopup__26XWa,
.DropDownMindset_submenupopup__3pr5g {
  background: white;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.DropDownMindset_submenupopup__3pr5g {
  margin-top: -5px;
  margin-left: 10px;
}

.DropDownMindset_item__2wd2n {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #b9b9b9;
  cursor: pointer;
  background: white;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.DropDownMindset_item__2wd2n:first-child {
}

.DropDownMindset_item__2wd2n:last-child {
}

.DropDownMindset_text__26_6S {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  margin-right: 0px;
}

.DropDownMindset_selected__Bgcbz {
  background: #eeeeee;
}

.DropDownMindset_selected__Bgcbz .DropDownMindset_text__26_6S {
  color: #f89d02;
}

.DropDownMindset_arrow__2K0qw {
  margin-left: 20px;
}

.DropDownMindset_disabled__1ITBG {
  color: rgba(255, 255, 255, 0.5);
}

.DropDownDomain_root__3-tn_ {
}

.DropDownDomain_dropdown__iKSF4 {
  display: flex;
  justify-content: space-between;
  height: 25px;
  line-height: 25px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: #282828;
  cursor: pointer;

  background: white;
  border: 1px solid #c9b6cc;
  box-sizing: border-box;
}

.DropDownDomain_popup__oM0Jt {
  position: absolute;
  z-index: 10000;
}

.DropDownDomain_dropdown-header__1rIAc {
}

.DropDownDomain_dropdown-text__nYpwD {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
  white-space: nowrap;
}

.DropDownDomain_dropdown-arrow__Ll562 {
  color: #131313;
  font-size: 20px;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.DropDownDomain_menupopup__2CFPb,
.DropDownDomain_submenupopup__2wxz_ {
  background: white;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.DropDownDomain_submenupopup__2wxz_ {
  margin-top: -5px;
  margin-left: 10px;
}

.DropDownDomain_item__2e3s4 {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  cursor: pointer;
  background: white;
  font-size: 13px;
  color: #282828;
}

.DropDownDomain_item__2e3s4:first-child {
}

.DropDownDomain_item__2e3s4:last-child {
}

.DropDownDomain_text__3I7zO {
  font-size: 13px;
  color: #282828;
  margin-right: 0px;
}

.DropDownDomain_selected__DKusC {
  background: #1a8ece;
}

.DropDownDomain_selected__DKusC .DropDownDomain_text__3I7zO {
  color: white;
}

.DropDownDomain_arrow__3dSNu {
  margin-left: 20px;
}

.DropDownDomain_disabled__3Xz2S {
  color: rgba(255, 255, 255, 0.5);
}

.DropDownContent_root__3lK3e {
}

.DropDownContent_dropdown__2p7co {
  display: flex;
  justify-content: space-between;
  height: 35px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  color: #131313;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #7ac0d1;
  box-sizing: border-box;
}

.DropDownContent_popup__2vhp5 {
  position: absolute;
  z-index: 10000;
}

.DropDownContent_dropdown-header__2kNCB {
}

.DropDownContent_dropdown-text__3XN-U {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.DropDownContent_dropdown-arrow__dTYB3 {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -18px;
  border-radius: 20px;
}

.DropDownContent_menupopup__jOaCH,
.DropDownContent_submenupopup__iwGZn {
  background: rgba(255, 255, 255, 0.26);
  border: 1px solid #7ac0d1;
  box-sizing: border-box;
  color: #131313;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 300px;
}

.DropDownContent_submenupopup__iwGZn {
  margin-top: -5px;
  margin-left: 10px;
}

.DropDownContent_item__2zwsC {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #7ac0d1;
  cursor: pointer;
  background: #e0f4f7;
}

.DropDownContent_item__2zwsC:first-child {
}

.DropDownContent_item__2zwsC:last-child {
}

.DropDownContent_text__2IXHn {
  font-size: 16px;
  font-weight: normal;
  color: #131313;
  margin-right: 0px;
  line-height: 20px;
  white-space: normal;
}

.DropDownContent_selected__39FZv {
  background-color: white;
}

.DropDownContent_selected__39FZv .DropDownContent_text__2IXHn {
}

.DropDownContent_arrow__1tSpW {
  margin-left: 20px;
}

.DropDownContent_disabled__34sdn {
  color: rgba(255, 255, 255, 0.5);
}

.Content_root__3H_xA {
  width: 100%;
}

.Content_header__1zVId {
  display: flex;
  justify-content: space-between;
  height: 43px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 12px;
  background: rgba(255, 255, 255, 0.1);
}

.Content_header__1zVId .Content_left__6GDUr {
  display: flex;
}

.Content_header__1zVId .Content_name__3ChNU {
}

.Content_header__1zVId .Content_divider__2-B23 {
  width: 13px;
  height: 43px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
}

.Content_header__1zVId .Content_priority__32oIP {
  display: flex;
  color: #056b90;
  font-size: 14px;
  margin-right: 20px;
}

.Content_header__1zVId .Content_priority__32oIP span {
  margin-left: 5px;
}

.Content_choose__2bcFc {
  display: flex;
  font-size: 32px;
  font-weight: bold;
  padding-left: 30px;
  height: 69px;
  line-height: 69px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.Content_choose__2bcFc .Content_text__21G6_ {
  margin-right: 10px;
}

.Content_choose__2bcFc .Content_divider__2-B23 {
  margin-left: 10px;
  margin-right: 10px;
}

.Content_skill__1PX02 {
  margin-left: 5px;
  font-size: 18px;
  display: inline;
}

.Content_standard__5_f6X {
  display: flex;
}

.Content_standard__5_f6X > div:nth-child(2) {
  margin-top: 3px;
  margin-left: 7px;
}

.Content_standard__5_f6X.Content_sub__3P0Z4 > div:nth-child(2) {
  margin-top: -2px;
  margin-left: 7px;
}

.Entry_root__2vv33 {
  width: 100%;
  min-height: 61px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.Entry_highlight__3TgNT {
  background: rgba(255, 255, 255, 0.1);
}

.Entry_skill__2M1X5 {
  min-height: 41px;
}

.Entry_small__2vmIj {
  font-size: 13px;
  padding-left: 25px;
  border-bottom: none;
  min-height: 0;
}

.PriorityStar_root__1WcOP {
  width: 15px;
  height: 15px;
}

.PriorityStar_root__1WcOP img {
  width: 100%;
}

.PrevBar_root__jOwCP {
  width: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  min-height: Calc(100vh - 135px);
}

.PrevBar_root__jOwCP.PrevBar_black__11qGc {
  box-shadow: none;
  background: white;
  min-height: 100%;
}

.PrevBar_button__nFVMV {
  color: white;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/content-arrow-left.png?v=3.0)
    no-repeat;
  background-position: center;
  height: 40px;
}

.PrevBar_root__jOwCP.PrevBar_black__11qGc .PrevBar_button__nFVMV {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/growth-filter-dropdown-arrow-left.png?v=3.0)
    no-repeat;
  background-position: center;
}

.PrevBar_link__3UQ6f {
  margin-top: 10px;
  -webkit-transform: translateX(-235px) translateY(240px) rotateZ(90deg);
          transform: translateX(-235px) translateY(240px) rotateZ(90deg);
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SelectionScreen_root__3da8W {
  display: flex;
  width: 100%;
}

.Content_root__2OtU- {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Content_header__tMAdm {
  width: 100%;
  line-height: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.16);
  position: relative;
}

.Content_header__tMAdm .Content_priority__2V-9u {
  display: flex;
  color: #056b90;
  font-size: 14px;
  left: 30px;
  position: absolute;
}

.Content_header__tMAdm .Content_priority__2V-9u > div:nth-child(1) {
  margin-top: 3px;
}

.Content_header__tMAdm .Content_priority__2V-9u span {
  margin-left: 5px;
}

.Content_header__tMAdm .Content_text__T-F5A {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.Content_row__3Nvn8 {
  display: flex;
  width: 100%;
  height: 105px;
}

.Content_root__2OtU- .Content_row__3Nvn8:nth-child(3) {
  height: 28px;
}

.Content_root__2OtU- .Content_row__3Nvn8:nth-child(5) {
  height: 100%;
}

.Content_title__3POn9 {
  height: 40px;
  width: 100%;
  background: #e9f3f9;
  cursor: pointer;
  color: #056b90;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-indent: 50px;
}

.Content_banner__2ivDi {
  width: 106px;
  height: 106px;
  position: absolute;
  right: -5px;
  top: -7px;
  z-index: 999;
}

.Content_banner__2ivDi img {
  width: 100%;
}

.Content_contents__3SJ3n {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.Box_root__3TO_x {
  box-shadow: 0 0 2px #888;
  position: relative;
}

.Category_root__17FAu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Category_icon__2S8dV {
  width: 36px;
}

.Category_icon__2S8dV img {
  width: 100%;
}

.Category_title__3jHWs {
  font-size: 16px;
  font-weight: bold;
}

.Category_results__173VK {
  font-size: 14px;
}

.Topic_root__1Ljx3 {
  width: 100%;
}

.Topic_title__3SSsX {
  height: 40px;
  width: 100%;
  background: #e9f3f9;
  cursor: pointer;
  color: #056b90;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-indent: 50px;
}

.Topic_row__1CL9D {
  display: flex;
  width: 100%;
}

.Topic_root__1Ljx3 .Topic_row__1CL9D:nth-child(3) {
  height: 28px;
}

.Topic_root__1Ljx3 .Topic_row__1CL9D:nth-child(5) {
  height: 100%;
}

.Center_root__3eIZe {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Item_root__2wIxR {
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
}

.Item_top__2D89X {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
}

.Item_title__36frX {
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  text-align: center;
}

.Item_title__36frX.Item_assigned__2fbci {
  color: rgba(41, 41, 41, 0.5);
}

.Item_root__2wIxR > .Item_assigned__2fbci {
  font-size: 14px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  height: 30px;
  line-height: 30px;
}

.Item_star__2WKDH {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
}

.SelectionBox_root__2pytl {
  background-color: #5599d3;
  color: white;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: relative;
}

.SelectionBox_root__2pytl.SelectionBox_selected__223xP {
  background-color: white;
  color: #292929;
}

.SelectionBox_icon__3CrtJ {
  margin-left: 10px;
  width: 20px;
  position: absolute;
}

.SelectionBox_text__2OxVp {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.Popup_root__3SjLA {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Popup_popup__t9ZOd {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.Popup_top__1lnvO {
  min-height: 57px;
  width: 485px;
  text-align: center;
  background: #282828;
  color: #fff;
  font-size: 18px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
}

.Popup_top__1lnvO .Popup_title__1eB48 {
  text-align: center;
  line-height: 50px;
}

.Popup_top__1lnvO .Popup_close__28KLf {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.Popup_bottom__YIqCw {
  width: 485px;
  height: 300px;
  padding: 5px 17px 5px 25px;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  color: #282828;
  background: #fff;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
}

.Popup_bottom__YIqCw .Popup_header__UrZtH {
}

.Popup_bottom__YIqCw .Popup_header__UrZtH .Popup_row__JsW9W {
  display: flex;
  justify-content: space-between;
}

.Popup_bottom__YIqCw .Popup_header__UrZtH .Popup_row__JsW9W .Popup_text__3t9vS {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.Popup_preview__3bsiD {
  width: 95px;
  height: 28px;
  border-radius: 8px;
  background: #4b8fd3;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  cursor: pointer;
  margin-top: 4px;
}

.Popup_bottom__YIqCw .Popup_entries__3qMk2 {
  width: 100%;
  height: Calc(100% - 50px);
  overflow-y: auto;
}

.Popup_line__3Awpm {
  width: 100%;
  height: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: black;
}

.Popup_top__1lnvO .Popup_goals__3VLk3 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  height: 45px;
  position: relative;
}

.Popup_top__1lnvO .Popup_goals__3VLk3 .Popup_skills__17Wb8 {
  font-weight: bold;
  font-size: 14px;
  width: 103px;
}

.Popup_left__104GW {
  position: absolute;
  left: 110px;
  top: 5px;
}

.Popup_right__3BqKx {
  position: absolute;
  left: 280px;
  top: 5px;
}

.Bold_root__LhdK3 {
  font-weight: bold;
}

.WhichContent_root__TBvld {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.WhichContent_mask__OfIQL {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.WhichContent_popup__1rqwr {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  color: #282828;
  font-size: 14px;
  padding: 10px;
  width: 905px;
  height: 350px;
  left: Calc(50% + 15px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 278px;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 6px 0 rgb(0 0 0 / 30%);
  display: flex;
  justify-content: space-between;
}

.WhichContent_column__3jPlu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  width: 225px;
}

.WhichContent_column__3jPlu .WhichContent_paragraph__1mHYE {
}

.WhichContent_column__3jPlu .WhichContent_footer__2Tw81 {
  font-size: 12px;
}

.WhichContent_close__3lQNZ {
  position: absolute;
  color: white;
  width: 21px;
  height: 21px;
  line-height: 21px;
  background: #0d0d0d;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  cursor: pointer;
  bottom: 5px;
  border-radius: 50%;
  left: Calc(49% - 20px);
  margin-bottom: 7px;
}

.HowToWaggle_root__3gqPF {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.HowToWaggle_mask__37fmC {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.HowToWaggle_popup__vf3Mi {
  position: absolute;
  color: #282828;
  font-size: 14px;
  padding: 20px;
  width: 905px;
  height: 550px;
  left: Calc(50% + 15px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 278px;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 6px 0 rgb(0 0 0 / 30%);
  display: flex;
  justify-content: space-between;
  background: #ccecf2;
}

.HowToWaggle_background__356Uq {
  position: absolute;
  left: 300px;
  width: Calc(100% - 300px);
}

.HowToWaggle_contents__M7Dy5 {
  width: 875px;
  position: absolute;
}

.HowToWaggle_top__37rHF {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.HowToWaggle_top__37rHF .HowToWaggle_left__1Cvp- {
  font-size: 22px;
  font-weight: bold;
}

.HowToWaggle_top__37rHF .HowToWaggle_right__32kT7 {
  font-size: 14px;
  display: flex;
}

.HowToWaggle_top__37rHF .HowToWaggle_right__32kT7 .HowToWaggle_text__1J0Gm {
  margin-top: 3px;
  cursor: pointer;
}

.HowToWaggle_top__37rHF .HowToWaggle_right__32kT7 .HowToWaggle_close__nsr8n {
  width: 21px;
  height: 21px;
  background: #fff;
  color: black;
  text-align: center;
  line-height: 21px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.StudentsTab_root__3fd9Q {
  display: flex;
  flex-direction: column;
}

.StudentsTab_row__1UYDV {
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
  width: 450px;
}

.StudentsTab_row__1UYDV:nth-child(1) {
  margin-left: 0px;
}

.StudentsTab_row__1UYDV .StudentsTab_number__3WuS_ {
  font-size: 17px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background: white;
  border-radius: 50%;
  color: black;
  text-align: center;
  line-height: 30px;
}

.StudentsTab_row__1UYDV .StudentsTab_text__3jmPL {
  font-size: 16px;
  margin-left: 10px;
}

.WaggleTab_root__1zzcF {
  display: flex;
  flex-direction: column;
}

.WaggleTab_row__ZZQap {
  display: flex;
  margin-bottom: 20px;
  width: 450px;
}

.WaggleTab_row__ZZQap .WaggleTab_number__1SXeU {
  font-size: 17px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background: white;
  border-radius: 50%;
  color: black;
  text-align: center;
  line-height: 30px;
}

.WaggleTab_row__ZZQap .WaggleTab_text__1_Aut {
  font-size: 16px;
  margin-left: 10px;
}

.StudentPracticeScreen_root__Xie1c {
  display: flex;
  width: 100%;
  height: Calc(100vh - 135px);
}

.CreateAssignmentsBar_root__31b3I {
  width: 30px;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: Calc(100vh - 145px);
  background: #009DD1;
  margin-top: 20px;

}

.CreateAssignmentsBar_button__fb3YV {
  color: white;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAUCAYAAABroNZJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF2SURBVDhP1ZS9isJQEIXPbiBExEosUmit0S6IAR9A7CwFWxF8AlujjU0KCyNYCgZCsJGA72An6AvYC4LaiLK7mb243kSjYLVfM3OmOPdn5t4PRVG+8CafLL7FyybRaJRlQZ6aSJKE8XiMxWKB4XDIqjyhJrFYDJPJBKqqks7n8xT9PDRJpVJwHAfpdJpVANd1WcZz1ySXy9ERkskkqwC2baPdbjPFEzApFoswTROJRIL05XLBYDBAp9MhfQ/OpFqtot/vIx6Pkz6dTuj1emQaxtWkVquh1WohEomQ9nbQ7XZhWRbpMK4mx+MR+/2eKUAQBGiaRvEZ3Nhns1kYhsFd6Gq1Qr1e5xbww93Jer1GpVLBcrlkld9OzWYzio8QfrrA9e18PmM6nUKWZWQyGap5I18qlejI3s78hL7iRqOBZrMJURRJHw4HFAoFym8JzMkto9EIuq5ju92S3u12FP289J94T6BcLmM+n2Oz2bDqH//wU3oM8A0LvnGpxJqmggAAAABJRU5ErkJggg==) no-repeat;
  background-position: center;
  height: 40px;
}

.CreateAssignmentsBar_link__qfajl {
  margin-top: 10px;
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
  white-space: nowrap;


  color: "#fff";

  /* UI Text/Small 2 - Open Sans Bold 14pt */
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CreateAssignmentsBar_root__31b3I.CreateAssignmentsBar_dark__25sDl {
  background: #009DD1;
}

.CreateAssignmentsBar_shadow__1Nqed {
  box-shadow: -5px 0 5px -3px rgba(0, 0, 0, 0.25);
}
.AssignmentBar_root__2T3wC {
  position: absolute;
  right: 0;
  top: 56px;
  bottom: 0;
  width: 286px;
  background: #282828;
}

.AssignmentBar_title__2GaKU {
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.AssignmentBar_title__2GaKU .AssignmentBar_icon__2CA_2 {
  margin-left: 20px;
}

.AssignmentBar_title__2GaKU .AssignmentBar_text__100Lp {
  margin-left: 10px;
}

.AssignmentBar_title__2GaKU:hover .AssignmentBar_text__100Lp {
  color: #449dac;
}

.AssignmentBar_entries__2mFY4 {
}

.AssignmentBar_button__Tigkk {
  background-color: #696969;
  border: none;
  border-radius: 10px;
  color: #282828;
  line-height: 20px;
  width: 200px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.AssignmentBar_button__Tigkk.AssignmentBar_active__3tvea {
  background-color: #fff;
}

.AssignmentBar_note__c6xeX {
  padding: 10px;
  width: 207px;
  background-color: #ff9;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-sizing: border-box;
}

.AssignmentBar_note__c6xeX .AssignmentBar_icon__2CA_2 {
  display: flex;
  justify-content: center;
}

.AssignmentBar_note__c6xeX .AssignmentBar_text__100Lp {
  font-size: 14px;
  color: black;
}

.Entry_root__SO876 {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px;
  padding-bottom: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.Entry_left__1pqdA {
  display: flex;
  width: 250px;
}

.Entry_icon__1yIPI {
  margin-top: -7px;
  margin-left: 10px;
  margin-right: 10px;
}

.Entry_title__3aDdU {
  font-size: 14px;
}

.Entry_right__17YYt {
}

.Entry_button__pHWHX {
  cursor: pointer;
}

.Assignments_root__3gR5n {
}

.Assignments_instructions__3Fy1f {
  font-size: 14px;
  color: #666666;
}

.Assignments_assignments__33mHt {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.Assignments_row__1-FWK {
  border-bottom: 2px solid #056b90;
  display: flex;
}

.Assignments_row__1-FWK > div {
  padding: 10px;
}

.Assignments_row__1-FWK > div:nth-child(1) {
  min-width: 250px;
  width: 250px;
  border-right: 2px solid #056b90;
}

.Assignments_row__1-FWK > div:nth-child(2) {
  min-width: 90px;
  width: 90px;
  border-right: 2px solid #056b90;
}

.Assignments_row__1-FWK > div:nth-child(3) {
  min-width: 90px;
  width: 90px;
  border-right: 2px solid #056b90;
}

.Assignments_row__1-FWK > div:nth-child(4) {
  min-width: 126px;
  width: 126px;
  border-right: 2px solid #056b90;
}

.Assignments_row__1-FWK > div:nth-child(5) {
  min-width: 70px;
  width: 70px;
}

.Assignments_row__1-FWK .Assignments_header__Tzc39 {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  background: #ffff99;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm:nth-child(1) {
  color: #666666;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm:nth-child(2) {
  color: #056b90;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm:nth-child(3) {
  color: #056b90;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm:nth-child(4) {
  color: #056b90;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_text__uKRKX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_class__1OVfy {
  cursor: pointer;
  display: flex;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_pencil__3Wzcj {
  margin-top: 8px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png?v=3.0)
    no-repeat scroll -174px -586px rgba(0, 0, 0, 0);
  width: 18px;
  height: 18px;
  margin-right: -4px;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_class__1OVfy:hover .Assignments_pencil__3Wzcj {
  background-position: -174px -612px;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_trash__1Uba2 {
  margin-top: 4px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -176px -1336px rgba(0, 0, 0, 0);
  width: 27px;
  height: 27px;
  margin-left: 25px;
  cursor: pointer;
}

.Assignments_row__1-FWK .Assignments_box__1mjbm .Assignments_trash__1Uba2:hover {
  background-position: -176px -1363px;
}

.Assignments_bottom__dLO-n {
  display: flex;
  justify-content: flex-end;
  height: 70px;
  border-top: 1px solid #056b90;
  padding-top: 30px;
  box-sizing: border-box;
}

.Assignments_bottom__dLO-n .Assignments_cancel__66yEL {
  color: #056b90;
  font-size: 18px;
  width: 70px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.Assignments_bottom__dLO-n .Assignments_cancel__66yEL:hover {
  color: #f89d02;
}

.Assignments_bottom__dLO-n .Assignments_assign__haydx {
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  height: 30px;
  line-height: 30px;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.Assignments_bottom__dLO-n .Assignments_assign__haydx:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.Assignments_buttons__3jyyJ {
  display: flex;
  justify-content: flex-end;
}

.Assignments_buttons__3jyyJ .Assignments_update__YF92s {
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 14px;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  cursor: pointer;
}

.Assignments_buttons__3jyyJ .Assignments_update__YF92s:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.Class_root__2gBV7 {
}

.Class_instructions__y8X4h {
  font-size: 14px;
  color: #666666;
}

.Class_assignments__LWy78 {
  margin-bottom: 20px;
}

.Class_row__2NR1r {
  border-bottom: 2px solid #056b90;
  display: flex;
}

.Class_row__2NR1r > div {
  padding: 10px;
}

.Class_row__2NR1r > div:nth-child(1) {
  min-width: 250px;
  width: 250px;
  border-right: 2px solid #056b90;
}

.Class_row__2NR1r > div:nth-child(2) {
  min-width: 140px;
  width: 140px;
  border-right: 2px solid #056b90;
}

.Class_row__2NR1r > div:nth-child(3) {
  min-width: 140px;
  width: 140px;
  border-right: 2px solid #056b90;
}

.Class_row__2NR1r > div:nth-child(4) {
  min-width: 126px;
  width: 126px;
}

.Class_row__2NR1r .Class_header__2U5vN {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.Class_row__2NR1r .Class_box__lkXl6 {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  background: #ffff99;
}

.Class_row__2NR1r .Class_box__lkXl6:nth-child(1) {
  color: #666666;
}

.Class_row__2NR1r .Class_box__lkXl6:nth-child(2) {
  color: #056b90;
}

.Class_row__2NR1r .Class_box__lkXl6:nth-child(3) {
  color: #056b90;
}

.Class_row__2NR1r .Class_box__lkXl6:nth-child(4) {
  color: #056b90;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_text__3au-A {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_assign__17I8n {
  color: #a9a9a9;
  margin-left: 10px;
  margin-top: 3px;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_class__2XerC {
  cursor: pointer;
  display: flex;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_pencil__2jNGF {
  margin-top: 8px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png?v=3.0)
    no-repeat scroll -174px -586px rgba(0, 0, 0, 0);
  width: 18px;
  height: 18px;
  margin-right: -4px;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_class__2XerC:hover .Class_pencil__2jNGF {
  background-position: -174px -612px;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_trash__1Hvds {
  margin-top: 4px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -176px -1336px rgba(0, 0, 0, 0);
  width: 27px;
  height: 27px;
  margin-left: 25px;
  cursor: pointer;
}

.Class_row__2NR1r .Class_box__lkXl6 .Class_trash__1Hvds:hover {
  background-position: -176px -1363px;
}

.Class_row__2NR1r .Class_column__3Pzyc {
  display: flex;
  color: #666666;
  font-size: 14px;
  font-weight: bold;
}

.Class_row__2NR1r .Class_column__3Pzyc .Class_checkbox__6PNXC {
  margin-right: 20px;
  cursor: pointer;
}

.Class_contents__RoRnZ {
  overflow-y: auto;
  overflow-x: hidden;
}

.Class_contents__RoRnZ .Class_row__2NR1r > div {
  padding: 5px;
}

.Class_contents__RoRnZ .Class_row__2NR1r > div:nth-child(1) {
  border-right: none;
}

.Class_contents__RoRnZ .Class_row__2NR1r > div:nth-child(2) {
  border-right: none;
}

.Class_contents__RoRnZ .Class_row__2NR1r > div:nth-child(3) {
  border-right: none;
}

.Class_contents__RoRnZ .Class_dropdown__xBiZV {
  margin-bottom: 20px;
}

.Class_bottom__1M4Xi {
  display: flex;
  justify-content: flex-end;
  height: 70px;
  border-top: 1px solid #056b90;
  padding-top: 30px;
  box-sizing: border-box;
}

.Class_bottom__1M4Xi .Class_cancel__1T87T {
  color: #056b90;
  font-size: 18px;
  width: 70px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.Class_bottom__1M4Xi .Class_cancel__1T87T:hover {
  color: #f89d02;
}

.Class_bottom__1M4Xi .Class_assign__17I8n {
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  height: 30px;
  line-height: 30px;
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.Class_bottom__1M4Xi .Class_assign__17I8n:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.Class_buttons__DRoam {
  display: flex;
  justify-content: flex-end;
}

.Class_buttons__DRoam .Class_update__3qKg- {
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 14px;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  color: white;
  cursor: pointer;
}

.Class_buttons__DRoam .Class_update__3qKg-:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.Checkbox_root__2C2SP {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border-style: 1px solid #777;
  background: white;
  cursor: pointer;
}

.Checkbox_selected__26n5C {
  background: #0075ff;
}

.DetailPopup_root__2KBDd {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
}

.DetailPopup_popup__RDdvM {
  position: absolute;
  left: 50%;
  top: 80px;

  -webkit-transform: translateX(-50%);

          transform: translateX(-50%);
  width: 764px;
}

.DetailPopup_top__2eVfG {
  display: flex;
  justify-content: space-between;
  background-color: #ccecf2;
  height: 43px;
  padding: 10px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.DetailPopup_title__1CwQF {
  font-size: 20px;
  font-weight: bold;
  font-family: OpenSans;
}

.DetailPopup_close__2qAIF {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  width: 12px;
  height: 16px;
  cursor: pointer;
}

.DetailPopup_contents__331tT {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}

.DetailPopup_paragraph__3ErsW {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 10px;
}

.CheckBox_root__ywQsa {
  border: 1px solid black;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 2px;
}

.CheckBox_check__3zUmM {
  background: black;
  width: 10px;
  height: 10px;
}

.ProgressBar_root__3jxd1 {
  width: 100%;
  height: 100%;
}

.ProgressBar_inner__3AkfU {
  width: 100%;
  height: 100%;
  display: flex;
}

.EntryRow_root__1eGFK {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 53px;
  color: #282822;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 18px;
  box-sizing: border-box;
}

.EntryRow_root__1eGFK>div>div:nth-child(2) {
  margin-top: -5px;
}

.EntryRow_root__1eGFK>div>div:nth-child(5) {
  text-align: center;
}

.EntryRow_root__1eGFK>div>div:nth-child(8) {
  display: flex;
}

.EntryRow_root__1eGFK>div>div:nth-child(8)>div:nth-child(1) {
  margin-right: 30px;
}

.EntryRow_selected__3rH5Q {
  background-color: rgba(0, 0, 0, 0.1);
}

.EntryRow_hideyrow__2d11Q .EntryRow_hidehover__3am5m {
  visibility: hidden;
}

.EntryRow_hideyrow__2d11Q:hover .EntryRow_hidehover__3am5m {
  visibility: visible !important;
}
.Button_root__1pF2_ {
  background-color: #4b8fd3;
  padding: 5px 15px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  min-width: 95px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.GoalPage_root__3HSBn {
  color: #282828;
  padding: 50px;
  padding-top: 20px;
}

.GoalPage_title__DuOxT {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.GoalPage_title__DuOxT .GoalPage_text__3QX4S {
}

.GoalPage_title__DuOxT .GoalPage_buttons__37bdM {
  display: flex;
}

.GoalPage_title__DuOxT .GoalPage_buttons__37bdM > div {
  margin-left: 20px;
}

.GoalPage_assignment__1vUZl {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
}

.GoalPage_assignment__1vUZl .GoalPage_pair__3mKBF {
  display: flex;
  margin-right: 50px;
}

.GoalPage_assignment__1vUZl .GoalPage_pair__3mKBF .GoalPage_text__3QX4S {
  margin-right: 10px;
}

.GoalPage_divider__2Cj00 {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: #677276;
}

.GoalPage_tabs__3HzP2 {
  margin-top: 10px;
  display: flex;
}

.Skills_root__1_cbK {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.Skills_root__1_cbK > .Skills_title__3__MD {
  font-family: ZillaSlab;
  font-size: 20px;
  color: #777;
}

.Skills_desc__37UrW {
  font-size: 13px;
  color: #777;
  margin-bottom: 20px;
}

.Skills_tabs__19UBg {
  color: #777;
  font-size: 14px;
}

.Skills_tabs__19UBg .Skills_blue__3PnCs {
}

.Skills_contents__1wseH {
  margin-top: 30px;
  margin-bottom: 50px;
}

.Skills_contents__1wseH .Skills_row__14pOj {
  display: flex;
  padding-left: 10px;
}

.Skills_contents__1wseH .Skills_row__14pOj:nth-child(1) {
  padding-left: 0;
  margin-bottom: 5px;
}

.Skills_contents__1wseH .Skills_row__14pOj:nth-child(1) {
  padding-left: 0;
  margin-bottom: 5px;
}

.Skills_contents__1wseH .Skills_row__14pOj > div:nth-child(1) {
  width: 330px;
}

.Skills_contents__1wseH .Skills_row__14pOj > div:nth-child(2) {
  width: 145px;
}

.Skills_contents__1wseH .Skills_row__14pOj > div:nth-child(3) {
  width: 195px;
}

.Skills_contents__1wseH .Skills_row__14pOj > div:nth-child(4) {
  width: 120px;
}

.Skills_header__2xGWK {
  font-weight: bold;
  font-size: 16px;
}

.Skills_header__2xGWK:nth-child(3) {
  text-align: center;
}

.Skills_header__2xGWK:nth-child(4) {
  text-align: center;
}

.Skills_box__118oE {
  color: #282828;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.Skills_box__118oE:nth-child(3) {
  text-align: center;
}

.Skills_box__118oE:nth-child(4) {
  text-align: center;
}

.Skills_box__118oE .Skills_title__3__MD {
  font-size: 16px;
  color: #282828;
  width: 300px;
}

.Skills_box__118oE .Skills_category__2c4s9 {
}

.Skills_box__118oE:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Skills_box__118oE:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Skills_box__118oE:nth-child(4) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Status_root__3HTZ4 {
  display: flex;
  font: 16px;
  margin-bottom: 5px;
}

.Status_value__D2nHN {
  color: #056b90;
  font-size: 16px;
}

.Summary_root__305XS {
  display: flex;
  padding: 50px;
}

.Summary_column__Zajov {
  color: #282828;
  width: 33%;
}

.Summary_title__3aZeR {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.Summary_pair__3S_gL {
  margin-bottom: 20px;
}

.Summary_pair__3S_gL .Summary_type__19Va7 {
  font-size: 16px;
  font-weight: bold;
}

.Summary_pair__3S_gL .Summary_value__2euF- {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.Summary_details__HJOoB {
  margin-top: -210px;
  text-align: center;
}

.Summary_details__HJOoB .Summary_value__2euF- {
  font-size: 55px;
}

.Summary_details__HJOoB .Summary_text__2r8XZ {
  font-weight: bold;
  font-size: 14px;
  margin-top: -10px;
}

.Summary_details__HJOoB .Summary_button__3i2eK {
  display: flex;
  justify-content: center;
  margin-top: 75px;
  cursor: pointer;
}

.Students_root__Lst4H {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.Students_root__Lst4H > .Students_title__1Q6VI {
  font-family: ZillaSlab;
  font-size: 20px;
  color: #777;
}

.Students_desc__2bmIQ {
  font-size: 13px;
  color: #777;
  margin-bottom: 20px;
}

.Students_dropdowns__1xu0x {
  display: flex;
  justify-content: space-between;
}

.Students_dropdowns__1xu0x .Students_left__3iCSv {
}

.Students_dropdowns__1xu0x .Students_right__3sWwv {
  display: flex;
}

.Students_dropdowns__1xu0x .Students_right__3sWwv .Students_show__31x6P {
  margin-right: 10px;
}

.Students_tabs__JYf6r {
  color: #777;
  font-size: 14px;
}

.Students_tabs__JYf6r .Students_blue__1E99Z {
}

.Students_contents__1MLJP {
  margin-top: 30px;
  margin-bottom: 50px;
}

.Students_contents__1MLJP .Students_row__lYc8M {
  display: flex;
}

.Students_contents__1MLJP .Students_row__lYc8M > div:nth-child(1) {
  width: 42px;
}

.Students_contents__1MLJP .Students_row__lYc8M > div:nth-child(2) {
  width: 208px;
}

.Students_contents__1MLJP .Students_row__lYc8M > div:nth-child(3) {
  width: 150px;
}

.Students_contents__1MLJP .Students_row__lYc8M > div:nth-child(4) {
  width: 175px;
}

.Students_contents__1MLJP .Students_row__lYc8M > div:nth-child(5) {
  width: 250px;
}

.Students_header__2NSxV {
  font-weight: bold;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Students_header__2NSxV:nth-child(3) {
}

.Students_header__2NSxV:nth-child(4) {
}

.Students_box__3g-vW {
  font-size: 16px;
  color: #282828;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Students_box__3g-vW:nth-child(3) {
}

.Students_box__3g-vW:nth-child(4) {
}

.Students_box__3g-vW .Students_title__1Q6VI {
  font-size: 16px;
  color: #282828;
}

.Students_box__3g-vW .Students_category__W1X4T {
}

.Students_box__3g-vW:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Students_box__3g-vW:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Students_box__3g-vW:nth-child(4) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TrackAssignmentSmallDropdown_root__1s21z {}

.TrackAssignmentSmallDropdown_dropdown__2ixel {
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 6px;
  margin-right: 20px;
  width: 290px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  font-weight: bold;

  color: #131313;
  cursor: pointer;

  background: #fff;
  border: 2px solid #ddd;
  box-sizing: border-box;
  border-radius: 5px;
}

.TrackAssignmentSmallDropdown_popup__2hyoK {
  position: absolute;
  z-index: 10000;
}

.TrackAssignmentSmallDropdown_dropdown-header__2pkEM {}

.TrackAssignmentSmallDropdown_dropdown-text__2scf3 {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.TrackAssignmentSmallDropdown_dropdown-arrow__2tCV9 {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.TrackAssignmentSmallDropdown_menupopup__2p_nE,
.TrackAssignmentSmallDropdown_submenupopup__3GUd1 {
  background: white;
  border: 1px solid #93bdc5;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.TrackAssignmentSmallDropdown_submenupopup__3GUd1 {
  margin-top: -5px;
  margin-left: 10px;
}

.TrackAssignmentSmallDropdown_item__3X_zI {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #93bdc5;
  cursor: pointer;
  background: white;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.TrackAssignmentSmallDropdown_item__3X_zI:first-child {}

.TrackAssignmentSmallDropdown_item__3X_zI:last-child {}

.TrackAssignmentSmallDropdown_text__3hXHE {
  font-size: 12px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  margin-right: 0px;
}

.TrackAssignmentSmallDropdown_selected__3np1e {
  background: #eeeeee;
}

.TrackAssignmentSmallDropdown_selected__3np1e .TrackAssignmentSmallDropdown_text__3hXHE {
  color: #f89d02;
}

.TrackAssignmentSmallDropdown_arrow__1dTI0 {
  margin-left: 20px;
}

.TrackAssignmentSmallDropdown_disabled__1AJ39 {
  color: rgba(255, 255, 255, 0.5);
}
.TrackAssignmentDropdown_root__38kGj {
}

.TrackAssignmentDropdown_dropdown__ZS4Mj {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 25px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 13px;
  margin-right: 20px;
  width: 290px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  font-weight: bold;

  color: #131313;
  cursor: pointer;

  background: #e0f7fb;
  border: 2px solid #93bdc5;
  box-sizing: border-box;
  border-radius: 5px;
}

.TrackAssignmentDropdown_popup__em5Y2 {
  position: absolute;
  z-index: 10000;
}

.TrackAssignmentDropdown_dropdown-header__3brRC {
}

.TrackAssignmentDropdown_dropdown-text__i7S3I {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.TrackAssignmentDropdown_dropdown-arrow__3zUHy {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.TrackAssignmentDropdown_menupopup__DlEDk,
.TrackAssignmentDropdown_submenupopup__1pWMU {
  background: white;
  border: 1px solid #93bdc5;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.TrackAssignmentDropdown_submenupopup__1pWMU {
  margin-top: -5px;
  margin-left: 10px;
}

.TrackAssignmentDropdown_item__2N57O {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #93bdc5;
  cursor: pointer;
  background: white;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.TrackAssignmentDropdown_item__2N57O:first-child {
}

.TrackAssignmentDropdown_item__2N57O:last-child {
}

.TrackAssignmentDropdown_text__1kyoO {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  margin-right: 0px;
}

.TrackAssignmentDropdown_selected__361Te {
  background: #eeeeee;
}

.TrackAssignmentDropdown_selected__361Te .TrackAssignmentDropdown_text__1kyoO {
  color: #f89d02;
}

.TrackAssignmentDropdown_arrow__sGSCY {
  margin-left: 20px;
}

.TrackAssignmentDropdown_disabled__1HIOi {
  color: rgba(255, 255, 255, 0.5);
}

.Growth_root__3yjop {
  width: 100%;
  height: Calc(100% - 57px);
}

.Growth_nav__3Nvd5 {
  position: fixed;
  width: 1000px;
  height: 20px;
  display: flex;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -26px;
  z-index: 9999999999999999;
}

.Growth_nav__3Nvd5 .Growth_divider__3AQDY {
  width: 1px;
  height: 26px;
  background-color: rgba(0, 0, 0, 1);
  margin-left: 10px;
  margin-right: 10px;
}

.WaggleGrowth_root__2x8z9 {
  width: 100%;
  height: 100%;
}

.WaggleGrowth_header__1mHA7 {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 57px;
}

.WaggleGrowth_header__1mHA7 .WaggleGrowth_title__3Wo6r {
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.WaggleGrowth_contents__2FZOQ {
  display: flex;
  width: 100%;
  height: Calc(100% - 10px);
  color: #282828;
}

.WaggleGrowth_left__3G_Kg {
  background: #e0f4f7;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-top: 10px;
}

.WaggleGrowth_left__3G_Kg .WaggleGrowth_top__3dKTD {
  font-size: 18px;
  font-weight: bold;
}

.WaggleGrowth_left__3G_Kg .WaggleGrowth_row__1NTrt {
  display: flex;
  margin-top: 20px;
}

.WaggleGrowth_left__3G_Kg .WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 {
  width: 24%;
  display: flex;
  flex-direction: column;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_text__sS4CY {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_button__5n5hQ {
  width: 210px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_discipline__10Aki {
  display: flex;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_discipline__10Aki > div {
  width: 89px;
  height: 40px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #7ac0d1;
  color: white;
  background-color: #7ac0d1;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_discipline__10Aki > div.WaggleGrowth_selected__yWhFw {
  color: black;
  background-color: white;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_discipline__10Aki > div:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.WaggleGrowth_row__1NTrt .WaggleGrowth_entry__2kL35 .WaggleGrowth_discipline__10Aki > div:nth-child(2) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.WaggleGrowthReport_root__aVJvi {
  height: 100%;
}

.WaggleGrowthReport_root__aVJvi > .WaggleGrowthReport_top__2kQE4 {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.WaggleGrowthReport_root__aVJvi > .WaggleGrowthReport_top__2kQE4 .WaggleGrowthReport_title__1XbVT {
  font-size: 22px;
  font-weight: bold;
  font-family: ZillaSlab;
}

.WaggleGrowthReport_top__2kQE4 .WaggleGrowthReport_button__1CE_w {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #dbf2f6;
  color: #6d797b;
  border-radius: 11px;
}

.WaggleGrowthReport_main__3Xs92 {
  display: flex;
  height: Calc(100% - 3px);
}

.WaggleGrowthReport_main__3Xs92 .WaggleGrowthReport_bar__3EqYH {
  background: #d3e4e7;
  width: 36px;
  min-width: 36px;
  height: 100%;
  margin-right: 36px;
}

.WaggleGrowthReport_content__3vj3H {
  width: 100%;
  margin-right: 50px;
  margin-left: 40px;
  height: 100%;
}

.WaggleGrowthReport_scroll__NICaE {
  max-height: 80%;
  height: 80%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_top__2kQE4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_selections__1C9ki {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_selections__1C9ki .WaggleGrowthReport_tabs__1h51r {
  display: flex;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_selections__1C9ki .WaggleGrowthReport_tabs__1h51r .WaggleGrowthReport_button__1CE_w {
  width: 270px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #056b90;
  background: #b7d4d9;
  text-align: center;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_selections__1C9ki .WaggleGrowthReport_tabs__1h51r .WaggleGrowthReport_button__1CE_w.WaggleGrowthReport_selected__3isxE {
  color: black;
  background: #def3f7;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_space__1MCOh {
  width: 898px;
  height: 40px;
  background: #e0f7fb;
  border: 1px solid #c2dfe4;
}

.WaggleGrowthReport_spacer__D7NC8 {
  flex-grow: 1;
}
.WaggleGrowthReport_updateLabel__1uNoH {
  padding-top: 7px;
  font-size: 12px;
}
.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_headers__1DSee {
  height: 100px;
  display: inline-flex;
  background: #d3e4e7;
  border-bottom: 2px solid #d3e4e7;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_headers__1DSee .WaggleGrowthReport_header__3JOfU {
  width: 148px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-transform: uppercase;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_row__1yfu7 {
  background: #d3e4e7;
  display: inline-flex;
  height: 38px;
  line-height: 38px;
  padding-bottom: 2px;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_row__1yfu7.WaggleGrowthReport_first__19w7R {
  margin-top: -2px;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_row__1yfu7 .WaggleGrowthReport_box__GuVZ9 {
  width: 148px;
  height: 100%;
  background: #def3f7;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 16px;
  text-align: center;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_row__1yfu7 .WaggleGrowthReport_box__GuVZ9:nth-child(1) {
  font-size: 12px;
  text-indent: 15px;
  text-align: left;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_category__3225M {
  background: #d3e4e7;
  height: 70px;
  display: inline-flex;

  padding-bottom: 2px;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_category__3225M .WaggleGrowthReport_type__2hQzx {
  width: 148px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-align: left;
  text-indent: 15px;
}

.WaggleGrowthReport_name__5hRNl {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
}

.WaggleGrowthReport_text__2jiIa {
  font-size: 13px;
}

.WaggleGrowthReport_content__3vj3H .WaggleGrowthReport_category__3225M .WaggleGrowthReport_box__GuVZ9 {
  width: 148px;
  height: 100%;
  background: #def3f7;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.WaggleGrowthReport_footer__9l09w {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.WaggleGrowthReport_footer__9l09w .WaggleGrowthReport_trend__20VC6 {
  display: flex;
}

.WaggleGrowthReport_footer__9l09w .WaggleGrowthReport_trend__20VC6 .WaggleGrowthReport_icon__1hRAD {
  margin-right: 10px;
}

.WaggleGrowthReport_footer__9l09w .WaggleGrowthReport_trend__20VC6 .WaggleGrowthReport_text__2jiIa {
  margin-top: 2px;
  font-size: 13px;
}

.WaggleGrowthReport_how__3DPOO {
  position: absolute;
  top: 133px;
  left: 45px;
  overflow-y: auto;
  max-height: Calc(100% - 76px);
  z-index: 999999999;
}

.WaggleGrowthReport_how__3DPOO .WaggleGrowthReport_close__oxrEW {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.Hexagon_root__1A9pA {
  position: absolute;
}

.Hexagon_hextop__3EHp_ {
  margin-top: 5px;
  width: 0;
  height: 0;
  border-radius: 4px;
}

.Hexagon_hex__3jOyD {
  background-color: #eeeeee;
  border-radius: 4px;
  position: relative;
  padding-bottom: 4px;
}

.Hexagon_hexbottom__3Wu4o {
  width: 0;
  margin-bottom: 20px;
  border-radius: 4px;
}

.GrowthMeasure_root__2T40J {
  width: 100%;
  height: 100%;
}

.GrowthMeasure_header__2al1W {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 57px;
  padding-top: 3px;
}

.GrowthMeasure_header__2al1W .GrowthMeasure_icon__6LQmJ {
  margin-top: 5px;
  margin-left: 50px;
  width: 40px;
}

.GrowthMeasure_header__2al1W .GrowthMeasure_icon__6LQmJ img {
  width: 100%;
}

.GrowthMeasure_header__2al1W .GrowthMeasure_title__3kJQ8 {
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-top: 13px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.GrowthMeasure_contents__roXvP {
  display: flex;
  width: 100%;
  height: Calc(100% - 10px);
  color: #282828;
}

.GrowthMeasure_left__1rPWb {
  background: #e0f4f7;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-top: 10px;
}

.GrowthMeasure_left__1rPWb .GrowthMeasure_top__2c_t9 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}

.GrowthMeasure_left__1rPWb .GrowthMeasure_row__3vO4H {
  display: flex;
  margin-top: 20px;
}

.GrowthMeasure_left__1rPWb .GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR {
  width: 24%;
  display: flex;
  flex-direction: column;
}

.GrowthMeasure_disabled__3Zgm1 {
  opacity: 0.5;
  pointer-events: none;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_text__24Hd4 {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_button__1L_Gu {
  width: 210px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_discipline__mnAuS {
  display: flex;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_discipline__mnAuS > div {
  width: 89px;
  height: 40px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #7ac0d1;
  color: white;
  background-color: #7ac0d1;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_discipline__mnAuS > div.GrowthMeasure_selected__3LHVh {
  color: black;
  background-color: white;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_discipline__mnAuS > div:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.GrowthMeasure_row__3vO4H .GrowthMeasure_entry__1sGxR .GrowthMeasure_discipline__mnAuS > div:nth-child(2) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.Report_root__2mEwq {
  height: Calc(100vh - 135px);
}

.Report_header__2Pjxd {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 57px;
  padding-top: 3px;
}

.Report_header__2Pjxd .Report_icon__2z8aU {
  margin-top: 5px;
  margin-left: 50px;
  width: 40px;
}

.Report_header__2Pjxd .Report_icon__2z8aU img {
  width: 100%;
}

.Report_header__2Pjxd .Report_title__3aB4_ {
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-top: 13px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.Report_top__1pKn4 .Report_button__VJRGz {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #dbf2f6;
  color: #6d797b;
  border-radius: 11px;
}

.Report_main__2fEgi {
  display: flex;
  height: 100%;
}

.Report_main__2fEgi .Report_bar__3j7uZ {
  background: white;
  min-width: 36px;
  width: 36px;
  height: 100%;
  margin-right: 36px;
}

.Report_content__Scu7B {
  width: 100%;
  margin-right: 50px;
  margin-left: 40px;
}

.Report_content__Scu7B .Report_top__1pKn4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  background: #def3f7;
  padding: 10px;
}

.Report_content__Scu7B .Report_selections__31F5V {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Report_content__Scu7B .Report_selections__31F5V .Report_tabs__3UtmI {
  display: flex;
}

.Report_content__Scu7B .Report_selections__31F5V .Report_tabs__3UtmI .Report_button__VJRGz {
  width: 270px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #056b90;
  background: #b7d4d9;
  text-align: center;
  cursor: pointer;
}

.Report_content__Scu7B .Report_selections__31F5V .Report_tabs__3UtmI .Report_button__VJRGz.Report_selected__vMhml {
  color: black;
  background: #def3f7;
}

.Report_content__Scu7B .Report_space__SC0Te {
  width: 100%;
  height: 10px;
  background: #def3f7;
}

.Report_how__11Of_ {
  position: absolute;
  top: 133px;
  left: 45px;

  overflow-y: auto;
  max-height: Calc(100% - 76px);
}

.Report_how__11Of_ .Report_close__2saSp {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.Report_inst__e8EL0 {
  display: flex;
  justify-content: flex-end;
}
.Italic_root__2DjUa {
  font-style: italic;
}

.Trend_root__1gnqh {
  height: 100%;
  margin-top: 17px;
}

.Trend_icon__1WEiA {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  height: 37px;
  border-radius: 5px;
  cursor: pointer;
}

.Trend_text__1ni3i {
  margin-top: -38px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  pointer-events: none;
}

.Trend_student__2us3n {
  font-size: 11px;
  color: white;
  pointer-events: none;
}

.Overview_root__1rflN {
}

.Overview_headers__2Mvoj {
  height: 50px;
  display: inline-flex;
  background: #7ac0d1;
  border-top: 1px solid #7ac0d1;
  border-bottom: 1px solid #7ac0d1;
}

.Overview_headers__2Mvoj .Overview_header__1o0Cp {
  width: 248px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.Overview_headers__2Mvoj .Overview_header__1o0Cp:nth-child(1) {
  width: 148px;
}

.Overview_row__1s9vE {
  background: #7ac0d1;
  display: inline-flex;
  height: 30px;
  line-height: 30px;
  padding-bottom: 2px;
}

.Overview_row__1s9vE.Overview_first__Qwi4J {
  margin-top: -4px;
}

.Overview_row__1s9vE .Overview_box__1vthw {
  width: 248px;
  height: 100%;
  background: #def3f7;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

.Overview_row__1s9vE .Overview_box__1vthw:nth-child(1) {
  font-size: 12px;
  width: 148px;
}

.Overview_category__SdTja {
  background: #7ac0d1;
  height: 70px;
  display: inline-flex;
  padding-bottom: 2px;
}

.Overview_category__SdTja.Overview_first__Qwi4J {
  margin-top: -3px;
}

.Overview_category__SdTja .Overview_type__3Uzs- {
  width: 248px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-align: center;
}

.Overview_category__SdTja .Overview_type__3Uzs-:nth-child(1) {
  width: 148px;
}

.Overview_category__SdTja .Overview_type__3Uzs- .Overview_name__3xJXM {
  font-size: 18px;
  font-weight: bold;
}

.Overview_category__SdTja .Overview_type__3Uzs- .Overview_text__1z_GF {
  font-size: 13px;
}

.Overview_category__SdTja .Overview_box__1vthw {
  width: 248px;
  height: 100%;
  background: #eef9fb;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.Overview_category__SdTja .Overview_box__1vthw:nth-child(1) {
  width: 148px;
}

.Overview_footer__3K-Xv {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.Overview_footer__3K-Xv .Overview_trend__2Gyro {
  display: flex;
}

.Overview_footer__3K-Xv .Overview_trend__2Gyro .Overview_icon__33uDI {
  margin-right: 10px;
}

.Overview_footer__3K-Xv .Overview_trend__2Gyro .Overview_text__1z_GF {
  margin-top: 2px;
  font-size: 13px;
}

.Popup_root__20F4S {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.Popup_popup__2JeL7 {
  position: absolute;
  width: 970px;
  height: Calc(100% - 250px);
  max-height: 571px;
  background: white;
  border-radius: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 200px;
  padding: 15px;
}

.Popup_close__2xHTr {
  position: absolute;
  top: 10px;
  right: 15px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  height: 16px;
  width: 12px;
  cursor: pointer;
}

.Popup_top__azQdx {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.Popup_top__azQdx .Popup_type__zJX3Y {
  width: 165px;
  background-color: #e2e2e2;
  padding: 12px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(248, 157, 2);
  margin-right: 20px;
}

.Popup_top__azQdx .Popup_students__3l6Ti {
  text-align: center;
}

.Popup_top__azQdx .Popup_students__3l6Ti .Popup_assessment__1qV2V {
  margin-top: -35px;
  font-size: 11px;
  font-weight: bold;
  color: #777777;
}

.Popup_top__azQdx .Popup_title__3TaLj {
  font-size: 20px;
  font-weight: bold;
  color: #282828;
  line-height: 60px;
  margin-left: 20px;
}

.Popup_rows__1J2Ii {
  overflow-y: auto;
  max-height: Calc(100% - 150px);
}

.Popup_row__3Tpa1 {
  display: flex;
}

.Popup_row__3Tpa1 > div {
  border: 1px solid #e2e2e2;
  padding: 10px;
}

.Popup_row__3Tpa1 > div:nth-child(1) {
  width: 279px;
}

.Popup_row__3Tpa1 > div:nth-child(2) {
  width: 249px;
}
.Popup_row__3Tpa1 > div:nth-child(3) {
  width: 65px;
}
.Popup_row__3Tpa1 > div:nth-child(4) {
  width: 75px;
}

.Popup_header__knBT3 {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  text-align: center;
  background: #edf6f9;
}

.Popup_header__knBT3:nth-child(1) {
  justify-content: flex-start;
}

.Popup_row__3Tpa1 .Popup_item__3IUKi {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Popup_row__3Tpa1 input {
  margin-right: 10px;
}

.Popup_box__2eH2Y {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #282828;
  text-align: center;
}

.Popup_box__2eH2Y:nth-child(1) {
  justify-content: flex-start;
}

.Popup_button__2vTy9 {
  width: 130px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

.Popup_footer__2W6gh {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.Domain_root__2KEKK {
  width: 883px;
}

.Domain_headers__b80xj {
  width: 100%;
  height: 50px;
  display: inline-flex;
  background: #7ac0d1;
  border-top: 1px solid #7ac0d1;
  border-bottom: 1px solid #7ac0d1;
}

.Domain_headers__b80xj .Domain_header__1GC0w {
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.Domain_headers__b80xj .Domain_header__1GC0w.Domain_first__2do6J {
  width: 148px;
  padding-left: 5px;
  box-sizing: border-box;
}

.Domain_row__1LHrD {
  width: 100%;
  background: #7ac0d1;
  display: inline-flex;
  height: 30px;
  line-height: 30px;
  padding-bottom: 2px;
}

.Domain_row__1LHrD.Domain_first__2do6J {
  margin-top: -4px;
}

.Domain_row__1LHrD .Domain_box__w3nI5 {
  height: 100%;
  background: #def3f7;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

.Domain_row__1LHrD .Domain_box__w3nI5:nth-child(1) {
  font-size: 12px;
  width: 148px;
}

.Domain_category__143i2 {
  background: #7ac0d1;
  height: 70px;
  display: flex;
  padding-bottom: 2px;
}

.Domain_category__143i2.Domain_first__2do6J {
  margin-top: -3px;
}

.Domain_category__143i2 .Domain_type__3WqaU {
  width: 248px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-align: center;
}

.Domain_category__143i2 .Domain_type__3WqaU:nth-child(1) {
  width: 148px;
}

.Domain_category__143i2 .Domain_type__3WqaU .Domain_name__Y8K50 {
  font-size: 18px;
  font-weight: bold;
}

.Domain_category__143i2 .Domain_type__3WqaU .Domain_text__2ACVu {
  font-size: 13px;
}

.Domain_category__143i2 .Domain_box__w3nI5 {
  width: 248px;
  height: 100%;
  background: #eef9fb;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.Domain_category__143i2 .Domain_box__w3nI5:nth-child(1) {
  width: 148px;
}

.Domain_footer__SWtIn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.Domain_footer__SWtIn .Domain_trend__hF4Kb {
  display: flex;
}

.Domain_footer__SWtIn .Domain_trend__hF4Kb .Domain_icon__16Tch {
  margin-right: 10px;
}

.Domain_footer__SWtIn .Domain_trend__hF4Kb .Domain_text__2ACVu {
  margin-top: 2px;
  font-size: 13px;
}

.Popup_root__32J-F {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.Popup_popup__UmwBF {
  position: absolute;
  width: 970px;
  height: Calc(100% - 250px);
  max-height: 571px;
  background: white;
  border-radius: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 200px;
  padding: 15px;
}

.Popup_close__Y2yz8 {
  position: absolute;
  top: 10px;
  right: 15px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  height: 16px;
  width: 12px;
  cursor: pointer;
}

.Popup_top__9P5LN {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.Popup_top__9P5LN .Popup_type__2fEyN {
  width: 165px;
  background-color: #e2e2e2;
  padding: 12px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(248, 157, 2);
  margin-right: 20px;
}

.Popup_top__9P5LN .Popup_students__3Y6PA {
  text-align: center;
}

.Popup_top__9P5LN .Popup_students__3Y6PA .Popup_assessment__2UmqW {
  margin-top: -35px;
  font-size: 11px;
  font-weight: bold;
  color: #777777;
}

.Popup_top__9P5LN .Popup_title__1FbsZ {
  font-size: 20px;
  font-weight: bold;
  color: #282828;
  line-height: 60px;
  margin-left: 20px;
}

.Popup_rows__1rVgd {
  overflow-y: auto;
  max-height: Calc(100% - 150px);
}

.Popup_row__3329n {
  display: flex;
}

.Popup_row__3329n > div {
  border: 1px solid #e2e2e2;
  padding: 10px;
}

.Popup_row__3329n > div:nth-child(1) {
  width: 279px;
}

.Popup_row__3329n > div:nth-child(2) {
  width: 65px;
}
.Popup_row__3329n > div:nth-child(3) {
  width: 249px;
}
.Popup_row__3329n > div:nth-child(4) {
  width: 75px;
}

.Popup_header__2JAkL {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  text-align: center;
  background: #edf6f9;
}

.Popup_header__2JAkL:nth-child(1) {
  justify-content: flex-start;
}

.Popup_header__2JAkL:nth-child(3) {
  background-color: #b4dae5;
}

.Popup_row__3329n .Popup_item__1OLuQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Popup_row__3329n input {
  margin-right: 10px;
}

.Popup_box__3H0Pq {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #282828;
  text-align: center;
}

.Popup_box__3H0Pq:nth-child(1) {
  justify-content: flex-start;
}

.Popup_button__2d_MC {
  width: 130px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

.Popup_footer__3NCSK {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.CheckBox_root__3ufjH {
  border-radius: 4px 4px 4px 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) inset;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  vertical-align: middle;
  width: 23px;
  background: white;
}

.CheckBox_selected__3pN34 {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -61px -344px #fff;
}

.CheckBox_selected__3pN34:hover {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -61px -384px #fff;
}

.Row_root__3wTqI {
  display: flex;
  width: 100%;
  height: 100%;
}

.Row_root__3wTqI > div:nth-child(1) {
  width: 186px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(2) {
  width: 126px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(3) {
  width: 91px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(4) {
  width: 139px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(5) {
  width: 140px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(6) {
  width: 90px;
  border-right: 2px solid white;
}

.Row_root__3wTqI > div:nth-child(7) {
  width: 112px;
  min-width: 112px;
}

.HeaderRow_root__14ZLQ {
  font-size: 14px;
  font-weight: bold;
  color: #777;
  height: 57px;
}

.HeaderRow_root__14ZLQ > div > div {
  padding-top: 20px;
  text-indent: 15px;
}

.HeaderRow_root__14ZLQ > div > div:nth-child(2),
.HeaderRow_root__14ZLQ > div > div:nth-child(4) {
  text-indent: 5px;
}

.HeaderRow_root__14ZLQ > div > div:nth-child(6) {
  text-indent: 0px;
  text-align: center;
  padding-top: 5px;
}

.EntryRow_root__2HvWk {
  font-size: 14px;
  font-weight: bold;
  color: #777;
  height: 40px;
}

.EntryRow_root__2HvWk > div > div {
  padding-top: 10px;
  text-indent: 15px;
}

.EntryRow_root__2HvWk > div > div:nth-child(6) {
  text-indent: 0px;
  text-align: center;
  padding-top: 5px;
}

.EntryRow_bottom__24EE8 {
  border-bottom: 2px solid #056b90;
}

.EntryRow_link__2fWq0 {
  color: #44a2d5;
  cursor: pointer;
}

.Add_root__d5cj0 {
  display: flex;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.Add_plus__1_QpD {
  margin-right: 5px;
  font-size: 20px;
  margin-top: -4px;
  color: white;
}

.Add_plus__1_QpD.Add_over__1NHgs {
  color: orange;
}

.Rosters_root__3TMY8 {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.Rosters_title__2jHOS {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: ZillaSlab;
}

.Rosters_header__mI3kv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 10px;
}

.Rosters_viewGroup__3sLHG {
  display: inline-block;
  margin-left: 40px;
}

.Rosters_print__1iIGA {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  justify-content: flex-end;
}

.Rosters_print__1iIGA > div:nth-child(2) {
  margin-right: 5px;
}

.Rosters_print__1iIGA .Rosters_divider__3AcDD {
  color: white;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
}

.Rosters_icon__1mnO2 {
  width: 30px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -59px -1157px rgba(0, 0, 0, 0);
  background-position: -126px -601px;
}

.Rosters_text__20O8u {
  color: #777;
  font-size: 14px;
}

.Rosters_content__3_knf {
  margin-top: 20px;
}

.Rosters_groupDropdownContainer__2-NgX {
  position: relative;
  margin-right: 4px;
  top: -5px;
}


.Popup_root__1LptK {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
}

.Popup_popup__SeWy2 {
  box-sizing: border-box;
  width: 760px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: Calc(50% + 50px);
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
}

.Popup_popup__SeWy2 .Popup_titlebar__2a2gx {
  width: 100%;
  background: #ccecf2;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}

.Popup_popup__SeWy2 .Popup_top__3waSz {
  width: 100%;
  height: 150px;
  background: #ccecf2;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.Popup_popup__SeWy2 .Popup_titlebar__2a2gx .Popup_header__YqEfg,
.Popup_popup__SeWy2 .Popup_top__3waSz .Popup_header__YqEfg {
  display: flex;
  justify-content: space-between;
}

.Popup_popup__SeWy2 .Popup_top__3waSz .Popup_header__YqEfg .Popup_title__L6U49 {
  font-size: 18px;
  font-weight: bold;
}

.Popup_popup__SeWy2 .Popup_top__3waSz .Popup_header__YqEfg .Popup_close__18p-U {
  cursor: pointer;
}

.Popup_popup__SeWy2 .Popup_top__3waSz .Popup_subheader__1ve6X {
  display: flex;
  justify-content: space-between;
}

.Popup_popup__SeWy2 .Popup_top__3waSz .Popup_tabs__1J4Ar {
  position: absolute;
  bottom: 0;
  left: 25px;
  display: flex;
}

.Popup_popup__SeWy2 .Popup_content__3DH0E {
  width: 100%;
  box-sizing: border-box;

  padding: 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.Popup_popup__SeWy2 .Popup_bottom__1XWNo {
  width: 100%;
  height: 390px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Button_root__12cVB {
  width: 140px;
  font-size: 16px;
  letter-spacing: 0.04em;
  border: 0;
  padding: 12px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.Button_root__12cVB:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}

.Summary_root__2q6cd {
  padding: 20px;
  height: 100%;
}

.Summary_top__pblcQ {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.Summary_split__2yMWD {
  display: flex;
  color: #5a5a5a;
}

.Summary_divider__UgEC4 {
  width: 1px;
  height: 250px;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
  background: #309acf;
}

.Content_root__2HhEe {
  width: 30%;
}

.Content_legend__1067P .Content_row__1UaDe {
  display: flex;
  margin-bottom: 3px;
}

.Content_legend__1067P .Content_row__1UaDe .Content_icon__1EsUA {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.Content_legend__1067P .Content_row__1UaDe .Content_text__2bAf4 {
  width: 120px;
  font-size: 16px;
  color: #282828;
}

.Content_legend__1067P .Content_row__1UaDe .Content_line__2Tm4w {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
}

.Content_legend__1067P .Content_row__1UaDe .Content_value__3S9Gd {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
  width: 30px;
}

.Content_title__q8mgz {
  font-size: 18px;
  color: #5a5a5a;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.Content_chart__30krl {
  height: 125px;
  position: relative;
}

.Standards_root__3Ery9 {
  padding: 20px;
  height: 100%;
}

.Standards_top__1-LJe {
  display: flex;
  flex-direction: row-reverse;
}

.Standards_details__2QDCv {
  font-size: 15px;
  margin-bottom: 20px;
}

.Standards_row__39IdD {
  display: flex;
  font-size: 13px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #5a5a5a;
}

.Standards_students__2BP-G {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.Box_root__wz6eq {
  height: 100%;
}


.ProficiencyTable_root__21z_i {
    width: 100%;
    height: 100%;
}

.ProficiencyTable_contents__1UN52 {    
    overflow-y: auto;
    height: Calc(100% - 40px);
}

.ProficiencyTable_row__3gqSs {
    width: 100%;
    height: 40px;
    display: flex;
}

.ProficiencyTable_row__3gqSs > div:nth-child(1)
{
    width: 200px;
    min-width: 200px;
}
.ProficiencyTable_row__3gqSs > div:nth-child(2)
{
    width: 125px;
    min-width: 125px;
}
.ProficiencyTable_row__3gqSs > div:nth-child(3)
{
    width: 125px;
    min-width: 125px;
}
.ProficiencyTable_row__3gqSs > div:nth-child(4)
{
    width: 125px; 
    min-width: 125px;
}
.ProficiencyTable_row__3gqSs > div:nth-child(5)
{
    width: 125px;
}

.ProficiencyTable_header__3Cmuq {
    background: #EDF6F9;
    border-bottom: 2px solid #DCEEF3;
    border-right: 1px solid #DCEEF3;
    color: #5a5a5a;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProficiencyTable_content__16ggZ {
    border-top: 1px solid #7AC0D1;
    border-bottom: 1px solid #7AC0D1;
    border-right: 1px solid #DCEEF3;
    color: #282828;
    font-size: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProficiencyTable_content__16ggZ:nth-child(1)
{
    color: #056B90;
    text-align: left;
}

.BackArrow_root__c1h_I
{
    display: flex;
    cursor: pointer;
}


.BackArrow_text__392P5 {
    color: #0a86cb;
    font-size: 13px;
    margin-left: 2px;
    margin-top: 2px;
  }
.Activity_root__3VoZ4 {
  padding: 20px;
  height: 100%;
}

.Activity_top__DBRTn {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: space-between;
}

.Activity_split__3ITmP {
  display: flex;
  border-bottom: 1px solid #7ac0d1;
}

.Activity_split__3ITmP .Activity_left__3s4GI {
  width: 50%;
  padding-top: 18px;
  padding-left: 50px;
  box-sizing: border-box;
  border-right: 1px solid #7ac0d1;
  padding-bottom: 10px;
}

.Activity_split__3ITmP .Activity_right__2UgEc {
  width: 50%;
  padding-left: 50px;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.Activity_row__5tGio {
  margin-top: 25px;
  display: flex;
}

.Activity_row__5tGio > div {
  width: 25%;
}

.Detail_root__2yJXF {
  display: flex;
}

.Detail_name__1crGt {
  font-size: 16px;
  color: #282828;
}

.Detail_line__2Tdok {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 25px;
}

.Detail_value__2pCbQ {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
}

.Item_root__2kpmW {
  text-align: center;
  color: #4f4f4f;
}

.Item_value__3Mhom {
  font-size: 30px;
  font-family: OpenSans;
  font-weight: bold;
}

.Item_min__u8ioH {
  margin-top: -5px;
  font-size: 18px;
  margin-bottom: 5px;
}

.Item_icon__33XwL {
  width: 50px;
  margin-bottom: 10px;
}

.Item_name__3Y5A_ {
  font-size: 16px;
}

.Skills_root__1G5Mu {
  padding: 20px;
  height: 100%;
}

.Skills_top__3xdVa {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Skills_top__3xdVa .Skills_left__2DRyl {
  display: flex;
  font-size: 15px;
}

.Skills_top__3xdVa .Skills_right__2Qypq {
  display: flex;
  flex-direction: row-reverse;
}

.Skills_top__3xdVa .Skills_right__2Qypq > div {
  margin-left: 10px;
}

.Skills_details__1xaLp {
  font-size: 15px;
  margin-bottom: 20px;
}

.Skills_row__3NarH {
  display: flex;
  font-size: 13px;
  text-align: center;
  color: #5a5a5a;
}

.Skills_students__2xrVn {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.Skills_student__3VaIh {
  display: flex;
  font-size: 13px;
  text-align: center;
  color: #5a5a5a;
  height: 40px;
  line-height: 40px;
}

.Writing_root__17lrN {
  padding: 20px;
  height: 100%;
}

.Writing_top__1BHvP {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: space-between;
}

.Writing_row__3mA9a {
  background-color: white;
  height: 40px;
  line-height: 40px;
  display: flex;
  border-bottom: 1px solid #dceef3;
}

.Writing_row__3mA9a.Writing_header__3ctPE {
  background-color: #edf6f9;
}

.Writing_row__3mA9a .Writing_text__1xpRH {
  font-size: 13px;
  font-weight: bold;
  color: #5a5a5a;
  width: 50%;
  text-indent: 20px;
}

.Writing_row__3mA9a .Writing_text__1xpRH:nth-child(1) {
  border-right: 1px solid #dceef3;
}


.Summary_root__1FdeS {
  padding: 20px;
  height: 100%;
}

.Summary_top__10v3s {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.Summary_split__2C9OH {
  display: flex;
}

.Summary_left__3HDqo {
  width: 50%;
}

.Summary_divider__2KY20 {
  width: 1px;
  height: 250px;
  margin-top: 50px;
  background: #309acf;
}

.Summary_right__34v8A {
  width: 45%;
  margin-left: 5%;
  position: relative;
}

.Summary_title__26J7y {
  font-size: 18px;
  color: #5a5a5a;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.Summary_title__26J7y .Summary_total__PTEKy {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
}

.Summary_left__3HDqo .Summary_row__3y8lI {
  display: flex;
  height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #309acf;
  width: 80%;
  margin-left: 10%;
  justify-content: space-between;
}

.Summary_right__34v8A .Summary_chart__2mk-K {
  height: 150px;
}

.Summary_right__34v8A .Summary_hours__21fmb {
  position: absolute;
  top: 85px;
  left: 115px;
  display: flex;
}

.Summary_right__34v8A .Summary_minutes__2Wryu {
  position: absolute;
  top: 105px;
  left: 115px;
  display: flex;
}

.Summary_right__34v8A .Summary_value__2vIre {
  font-size: 22px;
  color: #5a5a5a;
  width: 20px;
  text-align: right;
}

.Summary_right__34v8A .Summary_title__26J7y {
  font-size: 12px;
  color: #5a5a5a;
  margin-top: 8px;
  margin-left: 5px;
}

.Summary_right__34v8A .Summary_legend__2bQ5W {
}

.Summary_right__34v8A .Summary_legend__2bQ5W .Summary_row__3y8lI {
  display: flex;
}

.Summary_right__34v8A .Summary_legend__2bQ5W .Summary_row__3y8lI .Summary_icon__2auOQ {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.Summary_right__34v8A .Summary_legend__2bQ5W .Summary_row__3y8lI .Summary_text__1sOhA {
  width: 175px;
  font-size: 16px;
  white-space: nowrap;
  margin-right: 10px;
}

.Summary_right__34v8A .Summary_legend__2bQ5W .Summary_row__3y8lI .Summary_line__3skGc {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.Summary_right__34v8A .Summary_legend__2bQ5W .Summary_row__3y8lI .Summary_value__2vIre {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
}

.Summary_meter__1GXgB {
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
  margin-left: 35px;
  margin-bottom: -60px;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Standards_root__2HPpC {
  padding: 20px;
  height: 100%;
  position: relative;
}

.Standards_top__cLhPf {
  display: flex;
  flex-direction: row-reverse;
}

.Standards_details__3m9dN {
  font-size: 15px;
  margin-bottom: 20px;
}

.Standards_row__10lw6 {
  display: flex;
  font-size: 13px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #5a5a5a;
}

.Standards_students__1WMU3 {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.Standards_back__2B847 {
  position: absolute;
  top: 10px;
  left: 20px;
}
.Students_root__2u0ul {
  padding: 20px;
  height: 100%;
}

.Students_top__CBXXI {
  display: flex;
  flex-direction: row-reverse;
}

.Students_details__OIBa5 {
  font-size: 15px;
  margin-bottom: 20px;
}

.Students_row__1e8N0 {
  display: flex;
  font-size: 13px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #5a5a5a;
}

.Students_students__1FCOg {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.Skills_root__1tKvE {
  padding: 20px;
  height: 100%;
  position: relative;
}

.Skills_top__IYVdN {
  display: flex;
  flex-direction: row-reverse;
}

.Skills_details__1CG-e {
  font-size: 15px;
  margin-bottom: 20px;
}

.Skills_row__1Zf8C {
  display: flex;
  font-size: 13px;
  text-align: center;
  height: 60px;
  color: #5a5a5a;
}

.Skills_students__3hUpi {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.Skills_back__1O97R {
  position: absolute;
  top: 10px;
  left: 20px;
}

.Box_root__ibH-z {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GroupDialog_backdrop__3bzxE {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.GroupDialog_editDialog__10j7q {
  position: absolute;
  top: 20px;
  left: calc(50% - 340px);

  width: 680px;

  background-color: #ccecf2;

  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;

  font-family: ZillaSlab;
}

.GroupDialog_editDialog__10j7q .GroupDialog_header__1msGq {
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ccecf2;
  height: 43px;
  color: #282828;
  font-size: 22px;
  font-weight: bold;
  position: relative;
}

.GroupDialog_editDialog__10j7q .GroupDialog_footer__PZf2N {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.GroupDialog_editDialog__10j7q .GroupDialog_saveButton__3i9tL {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  padding: 12px 40px 10px 39px;
  box-shadow: none;
  border-style: none;
}

.GroupDialog_editDialog__10j7q .GroupDialog_cancelButton__nUhId {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  text-align: right !important;
  direction: ltr;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  color: #056b90;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 22px;
  margin-left: 5px;
  margin-top: 0;
  background: none;
  border: none;
  box-sizing: border-box;
  padding-top: 20px;
}

.GroupDialog_editDialog__10j7q .GroupDialog_header__1msGq .GroupDialog_closeButton__1iCsw {
  position: absolute;
  right: 10px;
  top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.GroupDialog_nameRow__-p2XO {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #056b90;
  cursor: pointer;
}
.GroupDialog_nameRow__-p2XO img {
  margin-left: 5px;
  position: relative;
  top: 7px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.GroupDialog_editDialog__10j7q .GroupDialog_body__1SftM {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.GroupDialog_buttonColumn__UN_7G {
  padding: 3px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.GroupDialog_studentListHeader__1APb1 h1 {
  margin: 0px;
  padding: 0px 0px 0px 20px;
  font-size: 18px;
}
.GroupDialog_studentListHeader__1APb1 {
  -webkit-text-size-adjust: 100%;
  font-style: normal;

  font-weight: bold;
  font-size: 1.125em;
  color: #616161;
  box-sizing: border-box;
  height: 1em;
  display: block;
  width: 300px;
  height: 440px;
}

.GroupDialog_studentList__3-1-O {
  margin-top: 15px;
  width: 300px;
  height: 400px;
  border-top: 2px solid #7bc2d2;
  border-bottom: 2px solid #7bc2d2;
}

.GroupDialog_studentList__3-1-O ul {
  margin: 0;
  padding-left: 20px;
}
.GroupDialog_studentList__3-1-O li {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 400;
  color: #777;
  visibility: visible;
  height: 40px;
  line-height: 1.6;
  font-family: inherit;
  font-size: 0.875em;
  box-sizing: border-box;
  margin: 0;
  direction: ltr;
  list-style: none;
  cursor: pointer;
  padding: 9px 10px 3px 9px;
  list-style-type: none !important;
}

.GroupDialog_studentList__3-1-O li.GroupDialog_selected__1BwPS {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 400;
  color: #777;
  visibility: visible;
  line-height: 1.6;
  font-family: inherit;
  font-size: 0.875em;
  box-sizing: border-box;
  margin: 0;
  direction: ltr;
  list-style: none;
  background-color: #fff !important;
  cursor: pointer;
  position: relative;
  padding: 9px 10px 3px 9px;
  list-style-type: none !important;
  margin-right: 35px;
}

.GroupDialog_rightArrow__3YBj7::before {
  border-width: 20px 20px 20px 0;
  left: -20px;
  right: auto;

  background: none repeat scroll 0 0 transparent;
  border-color: transparent #fff;
  border-style: solid;
  content: "";
  position: absolute;

  top: 0;
}

.GroupDialog_leftArrow__wVhyh::before {
  background: none repeat scroll 0 0 transparent;
  border-color: transparent #fff;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
}

.GroupDialog_moveButton__3EMFb {
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  direction: ltr;
  box-sizing: border-box;
  text-transform: none;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  box-shadow: none;
  border-style: none;
  border-radius: 12px;
  display: inline-block;
  height: 29px;
  margin: 6px 0 0;
  padding: 0;
  position: relative;
  width: 41px;
}

.GroupDialog_moveRight__10nfD::after {
  background: url(../../static/media/triumph-sprite-td.c4500801.png) no-repeat scroll -185px -920px
    transparent;
  content: "";
  height: 20px;
  left: 12px;
  position: absolute;
  top: 5px;
  width: 20px;
}

.GroupDialog_moveLeft__Gi95g::after {
  background: url(../../static/media/triumph-sprite-td.c4500801.png) no-repeat scroll -164px -920px
    transparent;

  content: "";
  height: 20px;
  left: 12px;
  position: absolute;
  top: 5px;
  width: 20px;
}

.GroupDialog_deleteButton__1Gg6s {
  font-family: ZillaSlab;
  background: none;
  border: none;
  font-weight: bold;
  color: #056b90;
  position: relative;
  font-size: 14px;
  margin-right: 40px;
  cursor: pointer;
}

.GroupDialog_deleteButton__1Gg6s::after {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMyA0SDE3VjFDMTcgMC40IDE2LjYgMCAxNiAwSDhDNy40IDAgNyAwLjQgNyAxVjRIMUMwLjQgNCAwIDQuNCAwIDVWN0MwIDcuNiAwLjQgOCAxIDhIMjNDMjMuNiA4IDI0IDcuNiAyNCA3VjVDMjQgNC40IDIzLjYgNCAyMyA0Wk05IDJIMTVWNEg5VjJaIiBmaWxsPSIjNDQ5REFDIi8+CjxwYXRoIGQ9Ik0yMSAxMEgzVjIzQzMgMjMuNiAzLjQgMjQgNCAyNEgyMEMyMC42IDI0IDIxIDIzLjYgMjEgMjNWMTBaTTkgMjBIN1YxNEg5VjIwWk0xMyAyMEgxMVYxNEgxM1YyMFpNMTcgMjBIMTVWMTRIMTdWMjBaIiBmaWxsPSIjNDQ5REFDIi8+Cjwvc3ZnPgo=);
  content: "";
  display: inline-block;
  height: 20px;
  margin: 0 0 0 4px;
  position: absolute;
  right: 86px;
  left: -24px;
  top: 3px;
  vertical-align: middle;
  width: 21px;
  background-size: 16px 20px;
  background-position: right;
  background-repeat: no-repeat;
}

.GroupDialog_nameInput__3KRH8 {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
  visibility: visible;
  line-height: 22px !important;
  direction: ltr;
  box-sizing: border-box;
  display: inline-block;
  padding-top: 0;
  vertical-align: middle;
  text-align: left;
  margin: 5px 2px 10px 0;
  color: #616161;
  font-family: ZillaSlab;
  font-size: 16px;
}
.GroupDialog_nameInput__3KRH8 input {
  position: relative;

  left: 3px;
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  direction: ltr;
  font-weight: 400;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  line-height: 2em;
  position: relative;
  border: 0 none transparent;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 5px;
  box-shadow: rgba(0, 1, 1, 0.298039) 2px 2px 3px inset;
  margin: 8px 5px 9px 0;
  font-size: 14px;
  background-color: #eee;
  color: #777;
  font-family: ZillaSlab;
  -webkit-appearance: none;
  margin-bottom: 7px;
  height: 2em;
  margin-top: 3px !important;
  display: inline-block;
  width: 250px !important;

  padding: 0 10px;
}

.GroupPrompt_groupPrompt__4JIz_ {
  background-color: #fff;
  box-sizing: border-box;
  position: absolute;
  width: 340px;

  top: 100px;
  left: calc(50% - 170px);
  border-radius: 6px;

  font-family: ZillaSlab;
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_header__1-4W0 {
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 10px;
  padding-top: 10px;
  background-color: #ccecf2;
  height: 43px;
  font-size: 20px;
  font-weight: bold;
  position: relative;
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_header__1-4W0 .GroupPrompt_closeButton__2qonZ {
  position: absolute;
  right: 10px;
  top: 5px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_footer__2Xn-w {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
}
.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_body__1LAlt {
  font-weight: bold;
  background-color: #fff;
  color: #777;
  padding: 20px 15px;
}

.GroupPrompt_groupPrompt__4JIz_ input {
  margin-top: 20px;
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  direction: ltr;
  line-height: normal;
  font-weight: 400;
  font-family: inherit;
  display: block;

  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  background-color: #eee;
  border: 0 none transparent;
  border-radius: 5px 5px 5px 5px;
  -webkit-box-shadow: 2px 2px 3px rgba(0, 1, 1, 0.3) inset;
  color: #666;
  font-size: 14px;
  height: 35px;
  -webkit-appearance: none;
  outline: none;
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_cancelButton__3n-sU {
  background: none;
  border: none;
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  text-align: right !important;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  color: #449dac;
  margin-right: 13px;
  cursor: pointer;
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_continueButton__2NM8U {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  box-shadow: none;
  border-style: none;
  padding: 10px 19px 10px 19px;
}

.GroupPrompt_groupPrompt__4JIz_ .GroupPrompt_continueButton__2NM8U:disabled {
  -webkit-text-size-adjust: 100%;
  cursor: pointer;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  background: #ccc !important;
  color: #fff !important;
  display: inline-block;

  border-radius: 8px;
  margin: 2px 0 0;
  box-shadow: none;
  border-style: none;
  padding: 10px 19px 10px 19px;
}

.MindsetReport_root__-hs1n {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 999999;
}

.MindsetReport_popup__2Fw9L {
  position: absolute;
  width: 700px;
  height: 500px;
  left: 50%;
  top: Calc(50% + 50px);
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  background: white;
  border-radius: 10px;
  padding: 25px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_close__2_SJK {
  position: absolute;
  top: 5px;
  right: 5px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png?v=3.0)
    no-repeat scroll -126px -445px transparent;
  width: 19px;
  height: 15px;
  cursor: pointer;
}

.MindsetReport_popup__2Fw9L .MindsetReport_top__3b6fz {
  font-size: 12px;
  font-weight: bold;
  color: #616161;
}

.MindsetReport_popup__2Fw9L > .MindsetReport_dropdown__3T5sP {
  margin-top: 20px;
  margin-bottom: 50px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV {
  display: flex;
  justify-content: space-between;

  color: #616161;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_dropdown__3T5sP {
  margin-top: -10px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_right__IikHr {
  display: flex;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_right__IikHr > .MindsetReport_text__2V82N {
  margin-top: -5px;
  margin-right: 10px;
  color: #777777;
  font-weight: bold;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_right__IikHr .MindsetReport_button__21VC- {
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  width: 115px;
  height: 28px;
  line-height: 28px;
  margin-left: 10px;
  font-size: 14px;
  display: inline-flex;
  cursor: pointer;
  border-color: #2284a1;
  margin-top: -12px;
  border-radius: 8px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_right__IikHr .MindsetReport_button__21VC- .MindsetReport_text__2V82N {
  text-align: center;
  color: white;
  margin-left: 20px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_actions__RSXPV .MindsetReport_right__IikHr .MindsetReport_button__21VC- .MindsetReport_icon__Qlt5F {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -3px -1709px rgba(0, 0, 0, 0);
  width: 18px;
  height: 17px;
  margin-top: 5px;
  margin-left: 5px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k {
  display: flex;

  color: #616161;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #dceef3;
  padding-top: 5px;
  padding-bottom: 5px;

  line-height: 20px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k.MindsetReport_first__1l9GV {
  line-height: 12px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_checkbox__xi7Np {
  width: 50px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_student__1GX-u {
  width: 160px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_proficiency__1caL0 {
  width: 110px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_responses__3oh-N {
  text-align: center;
  width: 80px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_attempts__3vbuw {
  text-align: center;
  width: 80px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_hints__3pVa4 {
  text-align: center;
  width: 95px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_time__3E3WO {
  text-align: center;
  width: 105px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_center__2rSPl {
  margin-top: 10px;
}

.MindsetReport_popup__2Fw9L .MindsetReport_row__1b5_k .MindsetReport_cb__g9PAD {
  margin-top: 8px;
}

.Checkbox_root__3N2TP {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border-style: 1px solid #777;
  background: white;
  cursor: pointer;
}

.Checkbox_selected__2uQ5F {
  background: #0075ff;
}

.Leaderboard_root__1LRzZ {
  width: 100%;
  min-height: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.Leaderboard_header__3X7EN {
  display: flex;
}

.Leaderboard_dates__En6hr {
  display: flex;
  width: 500px;
  height: 28px;
  background-color: rgba(255, 255, 255, 0.2);
  padding-left: 5px;
  padding-top: 7px;
  box-sizing: border-box;
}

.Leaderboard_dates__En6hr>div:nth-child(2),
.Leaderboard_dates__En6hr>div:nth-child(4) {
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  height: 15px;
  background-color: black;
}

.Leaderboard_divider__3ILTw {
  width: 500px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.2);
}
.Button_root__9KuQM {
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  font-weight: bold;
  width: 161px;
  height: 35px;

  box-shadow: inset 4px 0 8px -5px #333;
  background-color: rgba(0, 0, 0, 0.08);
  color: white;
  cursor: pointer;
}

.Button_root__9KuQM:hover {
  color: #449dac;
}

.Button_root__9KuQM.Button_selected__3yyiX {
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  box-shadow: none;
  pointer-events: none;
}

.Button_root__9KuQM.Button_selected__3yyiX:hover {
  color: black;
}

.GemEntry_root__390ce {
  display: flex;
  min-height: 105px;
  width: 100%;
  padding-bottom: 10px;
}

.GemEntry_type__1UdRb {
  display: flex;
  flex-direction: column;
  width: 75px;
  margin-left: 20px;
  margin-top: 20px;
}

.GemEntry_type__1UdRb .GemEntry_icon__D6TmZ {
  width: 80%;
}

.GemEntry_type__1UdRb .GemEntry_icon__D6TmZ img {
  width: 100%;
}

.GemEntry_type__1UdRb .GemEntry_text__3fq7d {
  font-size: 14px;
  width: 60px;
  text-align: center;
}

.GemEntry_description__2ypZ5 {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  margin-top: 10px;
  margin-left: 10px;
}

.GemEntry_count__1O8xL {
  font-size: 14px;
  margin-top: 10px;
}

.GemEntry_divider__2NXkT {
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.StudentRow_root__2qnqS {
  display: flex;
  margin-bottom: 5px;
}

.StudentRow_description__3tRvS {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  margin-top: 10px;
  margin-left: 10px;
}

.StudentRow_count__XfrUP {
  font-size: 14px;
  margin-top: 10px;
}

.PointEntry_root__gIjne {
  display: flex;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 20px;
}

.PointEntry_first__19Gsz {
  font-weight: bold;
  font-size: 17px;
  background: rgba(255, 255, 255, 0.5);
}

.PointEntry_index__3jx0H {
  margin-right: 5px;
  width: 20px;
}

.PointEntry_student__2s0SS {
  width: 300px;
}

.PointEntry_value__1pnS1 {
}

.ShoutOuts_root__3-rVP {
  width: 100%;
  min-height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.ShoutOuts_title__2ktUy {
  font-size: 22px;
  font-weight: bold;
  margin-left: 15px;
}

.Home_root__89nMA {
  padding-left: 10px;
  padding-right: 5px;
}

.Home_header__Ypy4a {
  padding-top: 15px;
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Home_header__Ypy4a a {
  color: #056b90;
  cursor: pointer;
  font-size: 16px;
}

.Home_header__Ypy4a a img {
  position: relative;
  top: 2px;
  left: -2px;
}

.Home_contents__22lym {
  display: flex;
}

.Home_left__3QaEV {
  width: 70%;
  margin-right: 50px;
}

.Home_left__3QaEV .Home_row__3qmE3 {
  display: flex;
  margin-bottom: 20px;
  margin-left: -5px;
  margin-right: -5px;
}

.Home_left__3QaEV .Home_row__3qmE3 > div {
  margin-left: 5px;
  margin-right: 5px;
}

.Home_left__3QaEV .Home_row__3qmE3 .Home_text__3YLw9 {
  position: absolute;
  font-size: 78px;
  font-weight: bold;
  right: 20px;
  top: -15px;
}

.Home_right__TGZYJ {
}

.Home_right__TGZYJ > div:nth-child(1) {
  margin-bottom: 20px;
}

.Home_backdrop__3XoUU {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.Home_assignmentSettings__3DzzB {
  width: 576px;
  background-color: #fff;
  min-height: 20px;
  position: absolute;
  top: 30px;
  left: calc(50% - 286px);
  border-radius: 6px;
  font-family: ZillaSlab;
  padding-top: 0px;
}

.Home_assignmentHeader__cO0tV {
  background-color: #ccecf2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 10px 10px 0px 20px;
  min-height: 30px;
}
.Home_assignmentHeader__cO0tV h1 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 1em;
}
.Home_assignmentHeader__cO0tV p {
  margin-top: 5px;
  display: inline-block;
  width: 400px;
  font-size: 14px;
}

.Home_assignmentBody__1ZZfW {
  padding: 20px;
}

.Home_assignmentSection__2jgAG {
  border-bottom: 1px solid #056b90;
  margin-bottom: 15px;
  position: relative;
}

.Home_assignmentSection__2jgAG p {
  display: block;
  width: 400px;
  font-size: 14px;
}

.Home_assignmentFooter__8QvVw {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px 20px 20px;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.Home_assignmentFooter__8QvVw .Home_saveButton__2KzyW {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;

  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  padding: 12px 40px 10px 39px;
  box-shadow: none;
  border-style: none;
  cursor: pointer;
}

.Home_assignmentFooter__8QvVw .Home_cancelButton__2FXpe {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  text-align: right !important;
  direction: ltr;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  color: #056b90;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 22px;
  margin-left: 5px;
  margin-top: 0;
  background: none;
  border: none;
  box-sizing: border-box;
  padding-top: 20px;
}
.Home_closeButton__1X3SY {
  position: absolute;
  right: 10px;
  top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.Home_onButton__3rGVy,
.Home_offButton__Zk6ZP {
  width: 60px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.Home_offButton__Zk6ZP {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAdCAYAAADy+d/cAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAPSSURBVFhH7ZhpSBVRGIbfDFtc0TTLFkuybFE0KJFCy7TtRy4Qaf2obKNFgigK9GdFUQRiFO0JlUXg8qOFNEsxRIUUbbEMSyvLtMS1jZZ5DzPjveN4vV6Sa90eGK7nnDnIc5bvfGeG/JKADWIn/9ocNitu1lKvaW5BZmUNCmtf4/H7ZjR1dOHHT+vukKF2Q+Dp5ICZYzwQ5jsBcYF+8PNwk1v7xqQ4hffnFiP32SusCvLHIj8fBI71hJezo/jH1oQD39jeicp3TbhbU4drFdWImjYJKVGhZg1Ar+LnS6qQlJWHlMhQ7AqfY3XRvuBAHCsow/68YqTFRiIxJEBu0UdX/HB+Ka6WP8XplYsRPM5Lrv07KH/biM3X7yA+eDr2RsyVa3vSQ5wznVb0EDnrY+Ht6iTXAqX1Dbj8sBoF0j5/9bFV1E0a5YpwaX+tme2PuRO9Rd1goKG1A9EXspA0f3avM28kzj0ddPQi7m+LN5rpndn5OFdSKZf02RASiNSYCLlkfTjzC05cRcXudbp73kh8bcZN+Hu6Y8/C7iUScz4LeVLwMIdIKfhlJ8bKJetz5F4pqps+IT1huVzTjSrO2Q4/noGXyVvUQGbOTGsxNfON7V1YkpaJ540touzhNBI3tscgwNtDlHOr6xF9Mkf8bUjO1mjx21tblP9EuWQMA97kA6dQsCOhx6yrCQzPaR5ZijT3dH+lCfuwr5aqhmbMOXRFlSbNHZ8RcjhDCA8EdKET3bSo4kxOeE4rMJBZil7fM0VVQnTjvFnoSk0Sz9G4MNG2J7NQrAYFw3f4GM6oqTY96EQ3Lao4MzImJwqM3pai7UupwhdvMdXLDcnLQuRaKaaEzsDSGT741PkFHwzE/yR0opsWVZxpKDMyBeXIsgRtX0pRzneUC5yG28u1gOMwe4x3cxYr4X1bt/jZB4/gsDNNPEEHLxmtBlNtetCJbloG/SVFO1iGmGrrC1WcCT9zXwUmJ5ai7Tva2QHujiNQ+7ENHV+/y7VA57fveNPSLqL7GBcHudZ4H2duWSFWhoKpNj3oRDctqjhvOUz4FZiRWYq2r5ckHjZlnIjoB26VyLVAevET3H5SJwaFgzMQ0IluWlRxXu14y1FgGmopen03zQ8QM2u4R3dL0ZwckaI7B2cgoBPdtKjivM/yaqfcs5l7MxnpL+yjl7czSSnbt1pEdgUORMnehD6PJEuhC53opuWPp6xZUso6WC6wplJWo6jOSzzvs0zwFZh7mzPzfIfvDhZpOtCFTnr8v5YaYpMfIhRs8tOTgk1+bDTE5j4v/8sYHWe2A/Abec84ALACUvEAAAAASUVORK5CYII=);
}
.Home_onButton__3rGVy {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAAeCAYAAACfWhZxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAObSURBVFhH7ZjvS1NRGMcfNZzV1HSCImqmTqxREv7oVa3U3qSS2ouKUMrqTQRJEtZfUBJKQfSqLAwpiFRQgyij2at0EiWz0DQzEQW11BFOsjrf493l3uM2dwfhcn1A7s659+A+e845z3Nu0G8GBRjB0jWgCEhpr6b34NQ3an4/SG22TzQwNUP2hUX6tcaLIjiISB8WSukx0VRsSqOyXUYyxkRJdz3jURqyNe0W6hz4QsSeciwtSXf8C11ICDMhyk/fSrVF5lXl3Uo3vOmj883P1zyiWsEMuFV2kCr37JR6VuJyTde+7KbLHV3/nDDAd8Z3h4M7VkQaEcaguQWH1OMf5CbF0YksE5lTEig5OpL3jczMkmV4jJp6bdQ9OsH7nESE6eha4T6XEVdJYw1nXr/ndxG+WZpPp3PdT1dwt7uPLrR0Sq1lMNXfXTq1Yo2rpjc2LX8Tbq0sWVUY4Bk8qwQucBKRI40oZ9c3kuOn9h36Nts4KnJMUotofNZOZx8/I8vQV962VpVTRqxB1X9893aqP3yA5ln6Uz6rxJsIi4gR120IIevFClW05UgjDyMtaaX9zBGVMIiP1NOj8mIupgT9RzMzpJZnsIa1CgOMwVgZ5sTdFMjSKDy05mFIZSXE8s+NPTbSX7lBhXee8IiG60Kp2pzN7ykpYLnUnJootdyDTctXlGPhBDclsjQqLa3s3ZbA5T5OTtM5ltMBpmmj1UaL7J9hB3UKoo3d1ttoY5f2FXGs6CZLo7TUSlJ0BL9O2H/wq5Oh6e98bwhnZWJ8hF7qJeoaGqN5xyKVspIxxbBF6nWNMy35gjhWdFPt3n+b8Tk79Y5N8tmRZ0zkm9haIEujeNfK6Mwcv8bpN/Grk1QWReyakIKokqbefh7tNEMUf8YdWAq+Io4V3WRpnFa08vrz8nRFOkLaAljDFdkmCmWHAFR1Yip6+PYDj7Zh80b+5w5UWr4ijhXdZGkcz/hpRQNOAYC0Zb9aRR0shWGzwo9RZ7HyeyLOaHsCpaWvKMfCCW5KZGmcR3E800oRS1FIV0qQso49aOM/iivQ3yLkThHU0ig0tIIxqjqcOXE3Barau+x+Kz3tH5Za/gFKywJjstTyzIvBESppaJVayxzakULNJ9XlqWr3xgEcRbo/AQlvIo5nRGG4wEnk/9HSCQ7gda96/E7cWyBcvT+HavJypR41LqXBen5d5FYaBNyLQSUB9Qp4vaJKWYFCAEoT/QF0ottMgY2qqgAAAABJRU5ErkJggg==);
}

.Home_purpleMessage__3b4Nh {
  color: #ab0049;
}

.Dropdown_root__2-4j8 {}

.Dropdown_dropdown__1c7U- {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 25px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 5px;
  margin-right: 20px;

  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 9px;
  font-size: 18px;
  font-weight: 700;

  color: #131313;
  cursor: pointer;

  background: #FBFCFD;
  border: 1px solid #BFC0C0;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  top: 8px;
}

.Dropdown_popup__J3g8C {
  position: absolute;
  z-index: 10000;
}

.Dropdown_dropdown-header__3XTyW {}

.Dropdown_dropdown-text__3Z4aC {
  margin-top: -2px;
  margin-right: 5px;
  line-height: 20px;
  margin-left: 10px;
}

.Dropdown_dropdown-arrow__3CqyK {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.Dropdown_menupopup__1huRH,
.Dropdown_submenupopup__3e_na {
  background: #FBFCFD;
  border: 1px solid #BFC0C0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Dropdown_submenupopup__3e_na {
  margin-top: -5px;
  margin-left: 10px;
}

.Dropdown_item__3W26h {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #BFC0C0;
  cursor: pointer;
  background: white;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.Dropdown_item__3W26h:first-child {}

.Dropdown_item__3W26h:last-child {}

.Dropdown_text__1gfEj {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  margin-right: 0px;
}

.Dropdown_selected__3GKzW {
  background: #eeeeee;
}

.Dropdown_selected__3GKzW .Dropdown_text__1gfEj {
  color: #f89d02;
}

.Dropdown_arrow__2pD5s {
  margin-left: 20px;
}

.Dropdown_disabled__3DO7C {
  color: rgba(255, 255, 255, 0.5);
}
@font-face {
  font-family: "Soleil";
  src: local("Soleil"), url(../../static/media/Soleil.8602c203.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("../../static/media/Proxima Nova Regular.e3143307.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("../../static/media/Proxima Nova Bold.aacabaf2.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(../../static/media/OpenSans-Regular.1b0809d5.ttf) format("truetype");
}

@font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"),
    url(../../static/media/ZillaSlab-Regular.77d29d36.ttf) format("truetype");
}

