.root {
  width: 100%;
}

.highlight {
  background: rgba(255, 255, 255, 0.1);
}

.module {
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.module > div:nth-child(1) {
  margin-left: 55px;
  margin-top: 12px;
}

.title {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 40px;
  font-size: 18px;
}

.text {
  line-height: 40px;
  font-size: 18px;
}

.link {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 40px;
  font-size: 18px;
}

.link > span {
  margin-left: 80px;
}
