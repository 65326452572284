.root {
  background-color: #4b8fd3;
  padding: 5px 15px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  min-width: 95px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}
