.root {
  width: 30%;
}

.legend .row {
  display: flex;
  margin-bottom: 3px;
}

.legend .row .icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.legend .row .text {
  width: 120px;
  font-size: 16px;
  color: #282828;
}

.legend .row .line {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
}

.legend .row .value {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
  width: 30px;
}

.title {
  font-size: 18px;
  color: #5a5a5a;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.chart {
  height: 125px;
  position: relative;
}
