.root {
}

.dropdown {
  display: flex;
  justify-content: space-between;
  height: 25px;
  user-select: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 2px;
  padding-right: 5px;
  font-size: 14px;
  color: #131313;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.34);
  box-sizing: border-box;
}

.popup {
  position: absolute;
  z-index: 10000;
}

.dropdown-header {
}

.dropdown-text {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.dropdown-arrow {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.menupopup,
.submenupopup {
  background: #292929;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: white;
  box-sizing: border-box;
  user-select: none;
}

.submenupopup {
  margin-top: -5px;
  margin-left: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;

  border-top: 1px solid #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.item:first-child {
  margin-top: 5px;
}

.item:last-child {
  margin-bottom: 5px;
}

.text {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-right: 0px;
}

.selected {
}

.selected .text {
}

.arrow {
  margin-left: 20px;
}

.disabled {
  color: rgba(255, 255, 255, 0.5);
}
