.root {
  width: 100%;
  min-height: 61px;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.highlight {
  background: rgba(255, 255, 255, 0.1);
}

.skill {
  min-height: 41px;
}

.small {
  font-size: 13px;
  padding-left: 25px;
  border-bottom: none;
  min-height: 0;
}
