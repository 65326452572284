.root {
  display: flex;
  width: 100%;
  height: 100%;
}

.root > div:nth-child(1) {
  width: 186px;
  border-right: 2px solid white;
}

.root > div:nth-child(2) {
  width: 126px;
  border-right: 2px solid white;
}

.root > div:nth-child(3) {
  width: 91px;
  border-right: 2px solid white;
}

.root > div:nth-child(4) {
  width: 139px;
  border-right: 2px solid white;
}

.root > div:nth-child(5) {
  width: 140px;
  border-right: 2px solid white;
}

.root > div:nth-child(6) {
  width: 90px;
  border-right: 2px solid white;
}

.root > div:nth-child(7) {
  width: 112px;
  min-width: 112px;
}
