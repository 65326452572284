.root {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: ZillaSlab;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 10px;
}

.viewGroup {
  display: inline-block;
  margin-left: 40px;
}

.print {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  justify-content: flex-end;
}

.print > div:nth-child(2) {
  margin-right: 5px;
}

.print .divider {
  color: white;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
}

.icon {
  width: 30px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -59px -1157px rgba(0, 0, 0, 0);
  background-position: -126px -601px;
}

.text {
  color: #777;
  font-size: 14px;
}

.content {
  margin-top: 20px;
}

.groupDropdownContainer {
  position: relative;
  margin-right: 4px;
  top: -5px;
}
