.root {
  width: 140px;
  font-size: 16px;
  letter-spacing: 0.04em;
  border: 0;
  padding: 12px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.root:hover {
  background: -webkit-linear-gradient(
    top,
    #ffbc4b 0,
    #feb840 21%,
    #f9a411 76%,
    #f89d03 100%
  );
}
