.background {
  width: 100%;
  padding: 15px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  color: #444547;
  box-sizing: border-box;
  position: relative;
}

.title {
  font-weight: bold;
  font-size: 19px;
  font-family: ProximaNovaBold;
}

.text {
  margin-top: 10px;
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
}
