.root {
  color: #282828;
  padding: 50px;
  padding-top: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.title .text {
}

.title .buttons {
  display: flex;
}

.title .buttons > div {
  margin-left: 20px;
}

.assignment {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
}

.assignment .pair {
  display: flex;
  margin-right: 50px;
}

.assignment .pair .text {
  margin-right: 10px;
}

.divider {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background: #677276;
}

.tabs {
  margin-top: 10px;
  display: flex;
}
