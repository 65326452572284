.root {
  width: 100%;
  height: Calc(100% - 57px);
}

.nav {
  position: fixed;
  width: 1000px;
  height: 20px;
  display: flex;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -26px;
  z-index: 9999999999999999;
}

.nav .divider {
  width: 1px;
  height: 26px;
  background-color: rgba(0, 0, 0, 1);
  margin-left: 10px;
  margin-right: 10px;
}
