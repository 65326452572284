.root {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 53px;
  color: #282822;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 18px;
  box-sizing: border-box;
}

.root>div>div:nth-child(2) {
  margin-top: -5px;
}

.root>div>div:nth-child(5) {
  text-align: center;
}

.root>div>div:nth-child(8) {
  display: flex;
}

.root>div>div:nth-child(8)>div:nth-child(1) {
  margin-right: 30px;
}

.selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.hideyrow .hidehover {
  visibility: hidden;
}

.hideyrow:hover .hidehover {
  visibility: visible !important;
}