.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  width: 100%;
  line-height: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.16);
  position: relative;
}

.header .priority {
  display: flex;
  color: #056b90;
  font-size: 14px;
  left: 30px;
  position: absolute;
}

.header .priority > div:nth-child(1) {
  margin-top: 3px;
}

.header .priority span {
  margin-left: 5px;
}

.header .text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.row {
  display: flex;
  width: 100%;
  height: 105px;
}

.root .row:nth-child(3) {
  height: 28px;
}

.root .row:nth-child(5) {
  height: 100%;
}

.title {
  height: 40px;
  width: 100%;
  background: #e9f3f9;
  cursor: pointer;
  color: #056b90;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-indent: 50px;
}

.banner {
  width: 106px;
  height: 106px;
  position: absolute;
  right: -5px;
  top: -7px;
  z-index: 999;
}

.banner img {
  width: 100%;
}

.contents {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
