.root {
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
}

.top {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  text-align: center;
}

.title.assigned {
  color: rgba(41, 41, 41, 0.5);
}

.root > .assigned {
  font-size: 14px;
  font-weight: bold;
  color: rgba(41, 41, 41, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  height: 30px;
  line-height: 30px;
}

.star {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
}
