.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.popup {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  color: #282828;
  font-size: 14px;
  padding: 10px;
  width: 905px;
  height: 350px;
  left: Calc(50% + 15px);
  transform: translateX(-50%);
  top: 278px;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 6px 0 rgb(0 0 0 / 30%);
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  width: 225px;
}

.column .paragraph {
}

.column .footer {
  font-size: 12px;
}

.close {
  position: absolute;
  color: white;
  width: 21px;
  height: 21px;
  line-height: 21px;
  background: #0d0d0d;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  cursor: pointer;
  bottom: 5px;
  border-radius: 50%;
  left: Calc(49% - 20px);
  margin-bottom: 7px;
}
