.root {
  padding: 20px;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: space-between;
}

.row {
  background-color: white;
  height: 40px;
  line-height: 40px;
  display: flex;
  border-bottom: 1px solid #dceef3;
}

.row.header {
  background-color: #edf6f9;
}

.row .text {
  font-size: 13px;
  font-weight: bold;
  color: #5a5a5a;
  width: 50%;
  text-indent: 20px;
}

.row .text:nth-child(1) {
  border-right: 1px solid #dceef3;
}
