.root {
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  font-weight: bold;
  width: 161px;
  height: 35px;

  box-shadow: inset 4px 0 8px -5px #333;
  background-color: rgba(0, 0, 0, 0.08);
  color: white;
  cursor: pointer;
}

.root:hover {
  color: #449dac;
}

.root.selected {
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  box-shadow: none;
  pointer-events: none;
}

.root.selected:hover {
  color: black;
}
