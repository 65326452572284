.root {
  text-align: center;
  color: #4f4f4f;
}

.value {
  font-size: 30px;
  font-family: OpenSans;
  font-weight: bold;
}

.min {
  margin-top: -5px;
  font-size: 18px;
  margin-bottom: 5px;
}

.icon {
  width: 50px;
  margin-bottom: 10px;
}

.name {
  font-size: 16px;
}
