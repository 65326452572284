.root {
  border: 1px solid black;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 2px;
}

.check {
  background: black;
  width: 10px;
  height: 10px;
}
