.root {
  height: Calc(100vh - 135px);
}

.header {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 57px;
  padding-top: 3px;
}

.header .icon {
  margin-top: 5px;
  margin-left: 50px;
  width: 40px;
}

.header .icon img {
  width: 100%;
}

.header .title {
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-top: 13px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.top .button {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #dbf2f6;
  color: #6d797b;
  border-radius: 11px;
}

.main {
  display: flex;
  height: 100%;
}

.main .bar {
  background: white;
  min-width: 36px;
  width: 36px;
  height: 100%;
  margin-right: 36px;
}

.content {
  width: 100%;
  margin-right: 50px;
  margin-left: 40px;
}

.content .top {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  background: #def3f7;
  padding: 10px;
}

.content .selections {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.content .selections .tabs {
  display: flex;
}

.content .selections .tabs .button {
  width: 270px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #056b90;
  background: #b7d4d9;
  text-align: center;
  cursor: pointer;
}

.content .selections .tabs .button.selected {
  color: black;
  background: #def3f7;
}

.content .space {
  width: 100%;
  height: 10px;
  background: #def3f7;
}

.how {
  position: absolute;
  top: 133px;
  left: 45px;

  overflow-y: auto;
  max-height: Calc(100% - 76px);
}

.how .close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.inst {
  display: flex;
  justify-content: flex-end;
}