.root {
  position: fixed;
  pointer-events: all;
  width: 100px;
  background: white;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid black;
  margin: 2px;
  margin-left: 10px;
  font-family: ProximaNova;
  z-index: 9999999999999;
  height: 15px;
  line-height: 15px;
}

.contents {
  position: relative;
  display: flex;
}

.checkbox {
  margin-top: -8px;
  margin-right: 10px;
  height: 30px;
}
