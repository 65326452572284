.root {
  border-radius: 4px 4px 4px 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) inset;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  vertical-align: middle;
  width: 23px;
  background: white;
}

.selected {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -61px -344px #fff;
}

.selected:hover {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -61px -384px #fff;
}
