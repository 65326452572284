.popup {
  position: fixed;
  width: 50%;
  max-width: 720px;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  transform: translate(0%, -40%);
  pointer-events: all;
  font-family: ProximaNova;
}

.animate {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
