.app {
  font-family: OpenSans;
  width: 100%;
  height: 100%;
  background-color: #FBFCFD;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.subwrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.right {
  width: Calc(100% - 175px);
  margin-left: 175px;
}

@media screen and (min-width: 768px) {
  .right {
    width: Calc(100% - 205px);
    margin-left: 205px;
  }
}

@media screen and (min-width: 1200px) {
  .right {
    width: Calc(100% - 240px);
    margin-left: 240px;
  }
}
