.root {
  width: 100%;
}

.title {
  height: 40px;
  width: 100%;
  background: #e9f3f9;
  cursor: pointer;
  color: #056b90;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-indent: 50px;
}

.row {
  display: flex;
  width: 100%;
}

.root .row:nth-child(3) {
  height: 28px;
}

.root .row:nth-child(5) {
  height: 100%;
}
