.root {
}

.dropdown {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 25px;
  user-select: none;
  padding-top: 13px;
  margin-right: 20px;
  width: 290px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  font-weight: bold;

  color: #131313;
  cursor: pointer;

  background: #e0f7fb;
  border: 2px solid #93bdc5;
  box-sizing: border-box;
  border-radius: 5px;
}

.popup {
  position: absolute;
  z-index: 10000;
}

.dropdown-header {
}

.dropdown-text {
  margin-top: -2px;
  margin-right: 0px;
  line-height: 20px;
  margin-left: 10px;
}

.dropdown-arrow {
  color: #131313;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: -7px;
  border-radius: 20px;
}

.menupopup,
.submenupopup {
  background: white;
  border: 1px solid #93bdc5;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  box-sizing: border-box;
  user-select: none;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.submenupopup {
  margin-top: -5px;
  margin-left: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  border-bottom: 1px solid #93bdc5;
  cursor: pointer;
  background: white;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.item:first-child {
}

.item:last-child {
}

.text {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  margin-right: 0px;
}

.selected {
  background: #eeeeee;
}

.selected .text {
  color: #f89d02;
}

.arrow {
  margin-left: 20px;
}

.disabled {
  color: rgba(255, 255, 255, 0.5);
}
