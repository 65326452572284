.root {
  position: absolute;
}

.hextop {
  margin-top: 5px;
  width: 0;
  height: 0;
  border-radius: 4px;
}

.hex {
  background-color: #eeeeee;
  border-radius: 4px;
  position: relative;
  padding-bottom: 4px;
}

.hexbottom {
  width: 0;
  margin-bottom: 20px;
  border-radius: 4px;
}
