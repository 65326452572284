.root {
  padding: 20px;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: space-between;
}

.split {
  display: flex;
  border-bottom: 1px solid #7ac0d1;
}

.split .left {
  width: 50%;
  padding-top: 18px;
  padding-left: 50px;
  box-sizing: border-box;
  border-right: 1px solid #7ac0d1;
  padding-bottom: 10px;
}

.split .right {
  width: 50%;
  padding-left: 50px;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.row {
  margin-top: 25px;
  display: flex;
}

.row > div {
  width: 25%;
}
