.root {
  font-size: 14px;
  font-weight: bold;
  color: #777;
  height: 57px;
}

.root > div > div {
  padding-top: 20px;
  text-indent: 15px;
}

.root > div > div:nth-child(2),
.root > div > div:nth-child(4) {
  text-indent: 5px;
}

.root > div > div:nth-child(6) {
  text-indent: 0px;
  text-align: center;
  padding-top: 5px;
}
