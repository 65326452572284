.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.editDialog {
  position: absolute;
  top: 20px;
  left: calc(50% - 340px);

  width: 680px;

  background-color: #ccecf2;

  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;

  font-family: ZillaSlab;
}

.editDialog .header {
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ccecf2;
  height: 43px;
  color: #282828;
  font-size: 22px;
  font-weight: bold;
  position: relative;
}

.editDialog .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.editDialog .saveButton {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  visibility: visible;
  text-transform: capitalize;
  direction: ltr;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  display: inline-block;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  border-radius: 8px;
  margin: 2px 0 0;
  padding: 12px 40px 10px 39px;
  box-shadow: none;
  border-style: none;
}

.editDialog .cancelButton {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  font-family: ZillaSlab;
  font-weight: 400;
  visibility: visible;
  text-transform: capitalize;
  text-align: right !important;
  direction: ltr;
  box-sizing: border-box;
  text-decoration: none;
  line-height: inherit;
  font-size: 1.125em;
  color: #056b90;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 22px;
  margin-left: 5px;
  margin-top: 0;
  background: none;
  border: none;
  box-sizing: border-box;
  padding-top: 20px;
}

.editDialog .header .closeButton {
  position: absolute;
  right: 10px;
  top: 0px;
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAELSURBVEhLYzzz5tN/BjoCJihNNzBqIdXBqIVUB8PfQoIlzcr+boY3z54waJiYMTiHR0NFUcHelUsZbpw5xSAiJcMQXlgKFcUOCPoQZBkIgAwEGYwOYJaBAEwtPkDQQmllVSgL01Jky0AA5ENCgGCQ/vz+nWHDjCkorgcFLwigWxaQkcPAzskJFcEOiKotsFmKDIi1DASISqUgg0AGYgsyUiwDAaKzBchAESkpKA8BQGLEWgYCRFuInkBgAD0hEQJEWYgtNSIHLymWErTwyKZ1WFMjepyC1IDUEgIELbx4+CCUhZpAsCUkZLW4AEELlXT0wDSyZTCAbilMLT4w2mqjOhi1kOqAzhYyMAAAdaR5hp5L5SEAAAAASUVORK5CYII=);
}

.nameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #056b90;
  cursor: pointer;
}
.nameRow img {
  margin-left: 5px;
  position: relative;
  top: 7px;
  transform: scale(0.8);
}

.editDialog .body {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.buttonColumn {
  padding: 3px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.studentListHeader h1 {
  margin: 0px;
  padding: 0px 0px 0px 20px;
  font-size: 18px;
}
.studentListHeader {
  -webkit-text-size-adjust: 100%;
  font-style: normal;

  font-weight: bold;
  font-size: 1.125em;
  color: #616161;
  box-sizing: border-box;
  height: 1em;
  display: block;
  width: 300px;
  height: 440px;
}

.studentList {
  margin-top: 15px;
  width: 300px;
  height: 400px;
  border-top: 2px solid #7bc2d2;
  border-bottom: 2px solid #7bc2d2;
}

.studentList ul {
  margin: 0;
  padding-left: 20px;
}
.studentList li {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  font-weight: 400;
  color: #777;
  visibility: visible;
  height: 40px;
  line-height: 1.6;
  font-family: inherit;
  font-size: 0.875em;
  box-sizing: border-box;
  margin: 0;
  direction: ltr;
  list-style: none;
  cursor: pointer;
  padding: 9px 10px 3px 9px;
  list-style-type: none !important;
}

.studentList li.selected {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  user-select: none;
  font-weight: 400;
  color: #777;
  visibility: visible;
  line-height: 1.6;
  font-family: inherit;
  font-size: 0.875em;
  box-sizing: border-box;
  margin: 0;
  direction: ltr;
  list-style: none;
  background-color: #fff !important;
  cursor: pointer;
  position: relative;
  padding: 9px 10px 3px 9px;
  list-style-type: none !important;
  margin-right: 35px;
}

.rightArrow::before {
  border-width: 20px 20px 20px 0;
  left: -20px;
  right: auto;

  background: none repeat scroll 0 0 transparent;
  border-color: transparent #fff;
  border-style: solid;
  content: "";
  position: absolute;

  top: 0;
}

.leftArrow::before {
  background: none repeat scroll 0 0 transparent;
  border-color: transparent #fff;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
}

.moveButton {
  -webkit-text-size-adjust: 100%;
  user-select: none;
  visibility: visible;
  direction: ltr;
  box-sizing: border-box;
  text-transform: none;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  transition: background-color 0.3s ease-out;
  font-size: 18px;
  font-weight: 400;
  border: 0 none;
  color: #fff !important;
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  box-shadow: none;
  border-style: none;
  border-radius: 12px;
  display: inline-block;
  height: 29px;
  margin: 6px 0 0;
  padding: 0;
  position: relative;
  width: 41px;
}

.moveRight::after {
  background: url(./triumph-sprite-td.png) no-repeat scroll -185px -920px
    transparent;
  content: "";
  height: 20px;
  left: 12px;
  position: absolute;
  top: 5px;
  width: 20px;
}

.moveLeft::after {
  background: url(./triumph-sprite-td.png) no-repeat scroll -164px -920px
    transparent;

  content: "";
  height: 20px;
  left: 12px;
  position: absolute;
  top: 5px;
  width: 20px;
}

.deleteButton {
  font-family: ZillaSlab;
  background: none;
  border: none;
  font-weight: bold;
  color: #056b90;
  position: relative;
  font-size: 14px;
  margin-right: 40px;
  cursor: pointer;
}

.deleteButton::after {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMyA0SDE3VjFDMTcgMC40IDE2LjYgMCAxNiAwSDhDNy40IDAgNyAwLjQgNyAxVjRIMUMwLjQgNCAwIDQuNCAwIDVWN0MwIDcuNiAwLjQgOCAxIDhIMjNDMjMuNiA4IDI0IDcuNiAyNCA3VjVDMjQgNC40IDIzLjYgNCAyMyA0Wk05IDJIMTVWNEg5VjJaIiBmaWxsPSIjNDQ5REFDIi8+CjxwYXRoIGQ9Ik0yMSAxMEgzVjIzQzMgMjMuNiAzLjQgMjQgNCAyNEgyMEMyMC42IDI0IDIxIDIzLjYgMjEgMjNWMTBaTTkgMjBIN1YxNEg5VjIwWk0xMyAyMEgxMVYxNEgxM1YyMFpNMTcgMjBIMTVWMTRIMTdWMjBaIiBmaWxsPSIjNDQ5REFDIi8+Cjwvc3ZnPgo=);
  content: "";
  display: inline-block;
  height: 20px;
  margin: 0 0 0 4px;
  position: absolute;
  right: 86px;
  left: -24px;
  top: 3px;
  vertical-align: middle;
  width: 21px;
  background-size: 16px 20px;
  background-position: right;
  background-repeat: no-repeat;
}

.nameInput {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  cursor: default;
  user-select: none;
  font-weight: bold;
  visibility: visible;
  line-height: 22px !important;
  direction: ltr;
  box-sizing: border-box;
  display: inline-block;
  padding-top: 0;
  vertical-align: middle;
  text-align: left;
  margin: 5px 2px 10px 0;
  color: #616161;
  font-family: ZillaSlab;
  font-size: 16px;
}
.nameInput input {
  position: relative;

  left: 3px;
  -webkit-text-size-adjust: 100%;
  user-select: none;
  visibility: visible;
  direction: ltr;
  font-weight: 400;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  line-height: 2em;
  position: relative;
  border: 0 none transparent;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 5px;
  box-shadow: rgba(0, 1, 1, 0.298039) 2px 2px 3px inset;
  margin: 8px 5px 9px 0;
  font-size: 14px;
  background-color: #eee;
  color: #777;
  font-family: ZillaSlab;
  -webkit-appearance: none;
  margin-bottom: 7px;
  height: 2em;
  margin-top: 3px !important;
  display: inline-block;
  width: 250px !important;

  padding: 0 10px;
}
