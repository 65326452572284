.root {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 57px;
  padding-top: 3px;
}

.header .icon {
  margin-top: 5px;
  margin-left: 50px;
  width: 40px;
}

.header .icon img {
  width: 100%;
}

.header .title {
  font-family: ZillaSlab;
  font-size: 20px;
  font-weight: bold;
  margin-top: 13px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.contents {
  display: flex;
  width: 100%;
  height: Calc(100% - 10px);
  color: #282828;
}

.left {
  background: #e0f4f7;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-top: 10px;
}

.left .top {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}

.left .row {
  display: flex;
  margin-top: 20px;
}

.left .row .entry {
  width: 24%;
  display: flex;
  flex-direction: column;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.row .entry .text {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}

.row .entry .button {
  width: 210px;
  height: 28px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-top: 7px;
  background: linear-gradient(0deg, #0081c9 0, #48a4d6 99%);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
}

.row .entry .discipline {
  display: flex;
}

.row .entry .discipline > div {
  width: 89px;
  height: 40px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #7ac0d1;
  color: white;
  background-color: #7ac0d1;
}

.row .entry .discipline > div.selected {
  color: black;
  background-color: white;
}

.row .entry .discipline > div:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.row .entry .discipline > div:nth-child(2) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
