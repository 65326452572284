.root {
  font-size: 14px;
  color: #056b90;
  cursor: pointer;
  font-weight: bold;
}

.root:hover {
  color: #f89d02;
}

.selected,
.selected:hover {
  color: black;
  pointer-events: none;
}
