.root {
  position: absolute;
  right: 0;
  top: 56px;
  bottom: 0;
  width: 286px;
  background: #282828;
}

.title {
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.title .icon {
  margin-left: 20px;
}

.title .text {
  margin-left: 10px;
}

.title:hover .text {
  color: #449dac;
}

.entries {
}

.button {
  background-color: #696969;
  border: none;
  border-radius: 10px;
  color: #282828;
  line-height: 20px;
  width: 200px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.button.active {
  background-color: #fff;
}

.note {
  padding: 10px;
  width: 207px;
  background-color: #ff9;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-sizing: border-box;
}

.note .icon {
  display: flex;
  justify-content: center;
}

.note .text {
  font-size: 14px;
  color: black;
}
