.root {
  height: 100%;
  margin-top: 17px;
}

.icon {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  height: 37px;
  border-radius: 5px;
  cursor: pointer;
}

.text {
  margin-top: -38px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  pointer-events: none;
}

.student {
  font-size: 11px;
  color: white;
  pointer-events: none;
}
