.root {
  width: 100%;
  height: 100%;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
}
