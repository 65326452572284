.root {
  width: 54px;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: Calc(100vh - 135px);
  background: rgba(255, 255, 255, 0.35);
}

.link {
  margin-top: 55px;
  transform: rotateZ(90deg);
  white-space: nowrap;
  font-size: 18px;
}

.root.dark {
  background: rgba(0, 0, 0, 0.1);
}
