.root {
  display: flex;
}

.name {
  font-size: 16px;
  color: #282828;
}

.line {
  color: #7ac0d1;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 25px;
}

.value {
  font-size: 16px;
  color: #5a5a5a;
  font-weight: bold;
}
