.root {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
  width: 450px;
}

.row:nth-child(1) {
  margin-left: 0px;
}

.row .number {
  font-size: 17px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background: white;
  border-radius: 50%;
  color: black;
  text-align: center;
  line-height: 30px;
}

.row .text {
  font-size: 16px;
  margin-left: 10px;
}
