.root {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.top {
  min-height: 57px;
  width: 485px;
  text-align: center;
  background: #282828;
  color: #fff;
  font-size: 18px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
}

.top .title {
  text-align: center;
  line-height: 50px;
}

.top .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.bottom {
  width: 485px;
  height: 300px;
  padding: 5px 17px 5px 25px;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  color: #282828;
  background: #fff;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
}

.bottom .header {
}

.bottom .header .row {
  display: flex;
  justify-content: space-between;
}

.bottom .header .row .text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.preview {
  width: 95px;
  height: 28px;
  border-radius: 8px;
  background: #4b8fd3;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  cursor: pointer;
  margin-top: 4px;
}

.bottom .entries {
  width: 100%;
  height: Calc(100% - 50px);
  overflow-y: auto;
}

.line {
  width: 100%;
  height: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: black;
}

.top .goals {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  height: 45px;
  position: relative;
}

.top .goals .skills {
  font-weight: bold;
  font-size: 14px;
  width: 103px;
}

.left {
  position: absolute;
  left: 110px;
  top: 5px;
}

.right {
  position: absolute;
  left: 280px;
  top: 5px;
}
