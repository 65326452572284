.root {
  display: flex;
  font: 16px;
  margin-bottom: 5px;
}

.value {
  color: #056b90;
  font-size: 16px;
}
