.root {
  width: 100%;
  min-height: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.header {
  display: flex;
}

.dates {
  display: flex;
  width: 500px;
  height: 28px;
  background-color: rgba(255, 255, 255, 0.2);
  padding-left: 5px;
  padding-top: 7px;
  box-sizing: border-box;
}

.dates>div:nth-child(2),
.dates>div:nth-child(4) {
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  height: 15px;
  background-color: black;
}

.divider {
  width: 500px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.2);
}