.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
}

.popup {
  position: absolute;
  left: 50%;
  top: 80px;

  transform: translateX(-50%);
  width: 764px;
}

.top {
  display: flex;
  justify-content: space-between;
  background-color: #ccecf2;
  height: 43px;
  padding: 10px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  font-family: OpenSans;
}

.close {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -128px -445px transparent;
  width: 12px;
  height: 16px;
  cursor: pointer;
}

.contents {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}

.paragraph {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 10px;
}
