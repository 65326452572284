.background {
  height: 55px;
  display: flex;
  align-items: center;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 15px;
}

.text {
  font-family: ProximaNovaBold;
  font-size: 22px;
  font-weight: 800;
}

.x-button {
  position: absolute;
  top: 7px;
  right: 15px;
  font-weight: 900;
  cursor: pointer;
  font-size: 30px;
  user-select: none;
  color: white;
  font-family: ProximaNovaBold;
}

.x-button:hover {
  top: 0px;
  right: 10px;
  font-size: 40px;
  /*transform: scale3d(1.27, 1.27, 1.27);*/
}
