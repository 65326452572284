.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 999999;
}

.popup {
  position: absolute;
  width: 700px;
  height: 500px;
  left: 50%;
  top: Calc(50% + 50px);
  transform: translateX(-50%) translateY(-50%);
  background: white;
  border-radius: 10px;
  padding: 25px;
}

.popup .close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png?v=3.0)
    no-repeat scroll -126px -445px transparent;
  width: 19px;
  height: 15px;
  cursor: pointer;
}

.popup .top {
  font-size: 12px;
  font-weight: bold;
  color: #616161;
}

.popup > .dropdown {
  margin-top: 20px;
  margin-bottom: 50px;
}

.popup .actions {
  display: flex;
  justify-content: space-between;

  color: #616161;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup .actions .dropdown {
  margin-top: -10px;
}

.popup .actions .right {
  display: flex;
}

.popup .actions .right > .text {
  margin-top: -5px;
  margin-right: 10px;
  color: #777777;
  font-weight: bold;
}

.popup .actions .right .button {
  background: -webkit-linear-gradient(
    top,
    #48a5d6 0,
    #41a1d5 16%,
    #0d88cc 79%,
    #0182ca 100%
  );
  width: 115px;
  height: 28px;
  line-height: 28px;
  margin-left: 10px;
  font-size: 14px;
  display: inline-flex;
  cursor: pointer;
  border-color: #2284a1;
  margin-top: -12px;
  border-radius: 8px;
}

.popup .actions .right .button .text {
  text-align: center;
  color: white;
  margin-left: 20px;
}

.popup .actions .right .button .icon {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/triumph-sprite-td.png)
    no-repeat scroll -3px -1709px rgba(0, 0, 0, 0);
  width: 18px;
  height: 17px;
  margin-top: 5px;
  margin-left: 5px;
}

.popup .row {
  display: flex;

  color: #616161;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #dceef3;
  padding-top: 5px;
  padding-bottom: 5px;

  line-height: 20px;
}

.popup .row.first {
  line-height: 12px;
}

.popup .row .checkbox {
  width: 50px;
}

.popup .row .student {
  width: 160px;
}

.popup .row .proficiency {
  width: 110px;
}

.popup .row .responses {
  text-align: center;
  width: 80px;
}

.popup .row .attempts {
  text-align: center;
  width: 80px;
}

.popup .row .hints {
  text-align: center;
  width: 95px;
}

.popup .row .time {
  text-align: center;
  width: 105px;
}

.popup .row .center {
  margin-top: 10px;
}

.popup .row .cb {
  margin-top: 8px;
}
