.root {
  width: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  min-height: Calc(100vh - 135px);
}

.root.black {
  box-shadow: none;
  background: white;
  min-height: 100%;
}

.button {
  color: white;
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/content-arrow-left.png?v=3.0)
    no-repeat;
  background-position: center;
  height: 40px;
}

.root.black .button {
  background: url(https://practice.gogetwaggle.com/teacher/assets/images/growth-filter-dropdown-arrow-left.png?v=3.0)
    no-repeat;
  background-position: center;
}

.link {
  margin-top: 10px;
  transform: translateX(-235px) translateY(240px) rotateZ(90deg);
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
