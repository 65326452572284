.background {
  width: 100%;
  padding: 35px;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #444547;
  box-sizing: border-box;
  position: relative;
}

.text {
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
}
