.root {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.root > .title {
  font-family: ZillaSlab;
  font-size: 20px;
  color: #777;
}

.desc {
  font-size: 13px;
  color: #777;
  margin-bottom: 20px;
}

.tabs {
  color: #777;
  font-size: 14px;
}

.tabs .blue {
}

.contents {
  margin-top: 30px;
  margin-bottom: 50px;
}

.contents .row {
  display: flex;
  padding-left: 10px;
}

.contents .row:nth-child(1) {
  padding-left: 0;
  margin-bottom: 5px;
}

.contents .row:nth-child(1) {
  padding-left: 0;
  margin-bottom: 5px;
}

.contents .row > div:nth-child(1) {
  width: 330px;
}

.contents .row > div:nth-child(2) {
  width: 145px;
}

.contents .row > div:nth-child(3) {
  width: 195px;
}

.contents .row > div:nth-child(4) {
  width: 120px;
}

.header {
  font-weight: bold;
  font-size: 16px;
}

.header:nth-child(3) {
  text-align: center;
}

.header:nth-child(4) {
  text-align: center;
}

.box {
  color: #282828;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.box:nth-child(3) {
  text-align: center;
}

.box:nth-child(4) {
  text-align: center;
}

.box .title {
  font-size: 16px;
  color: #282828;
  width: 300px;
}

.box .category {
}

.box:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box:nth-child(4) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
