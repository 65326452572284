.root {
  padding: 20px;
  height: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.top .left {
  display: flex;
  font-size: 15px;
}

.top .right {
  display: flex;
  flex-direction: row-reverse;
}

.top .right > div {
  margin-left: 10px;
}

.details {
  font-size: 15px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  font-size: 13px;
  text-align: center;
  color: #5a5a5a;
}

.students {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
}

.student {
  display: flex;
  font-size: 13px;
  text-align: center;
  color: #5a5a5a;
  height: 40px;
  line-height: 40px;
}
