.root {
  width: 883px;
}

.headers {
  width: 100%;
  height: 50px;
  display: inline-flex;
  background: #7ac0d1;
  border-top: 1px solid #7ac0d1;
  border-bottom: 1px solid #7ac0d1;
}

.headers .header {
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.headers .header.first {
  width: 148px;
  padding-left: 5px;
  box-sizing: border-box;
}

.row {
  width: 100%;
  background: #7ac0d1;
  display: inline-flex;
  height: 30px;
  line-height: 30px;
  padding-bottom: 2px;
}

.row.first {
  margin-top: -4px;
}

.row .box {
  height: 100%;
  background: #def3f7;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

.row .box:nth-child(1) {
  font-size: 12px;
  width: 148px;
}

.category {
  background: #7ac0d1;
  height: 70px;
  display: flex;
  padding-bottom: 2px;
}

.category.first {
  margin-top: -3px;
}

.category .type {
  width: 248px;
  height: 100%;
  background: #f5fbfc;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
  text-align: center;
}

.category .type:nth-child(1) {
  width: 148px;
}

.category .type .name {
  font-size: 18px;
  font-weight: bold;
}

.category .type .text {
  font-size: 13px;
}

.category .box {
  width: 248px;
  height: 100%;
  background: #eef9fb;
  text-align: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.category .box:nth-child(1) {
  width: 148px;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footer .trend {
  display: flex;
}

.footer .trend .icon {
  margin-right: 10px;
}

.footer .trend .text {
  margin-top: 2px;
  font-size: 13px;
}
