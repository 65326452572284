.root {
  background-color: #5599d3;
  color: white;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: relative;
}

.root.selected {
  background-color: white;
  color: #292929;
}

.icon {
  margin-left: 10px;
  width: 20px;
  position: absolute;
}

.text {
  display: inline-block;
  width: 100%;
  text-align: center;
}
